$chevron-down: "../../../assets/icons/chevron-down.svg";

input, textarea {
    display: block;
    width: 100%;
    padding: 8px;

    color: black;
    line-height: unset;

    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px;
    -webkit-appearance: none;

    background-color: transparent;
    transition: all .2s ease;

    &::placeholder {
        font-weight: 300;
    }

    &:focus {
        box-shadow: black 0 2px;
        border: 1px solid black;
    }

    @media only screen and (max-width: 1000px) {
        &::placeholder {
            font-size: 80%;
        }
    }
}

label {
    font-size: 1.25rem;

    //@media only screen and (max-width: var(--bp-xs)) {
    //    font-size: 24px;
    //}
}

input {
    font-size: 1.25rem;

    //@media only screen and (max-width: var(--bp-xs)) {
    //    font-size: 24px;
    //}
}

textarea {
    font-size: 1.25rem;
    resize: none;
    min-height: 5rem;
    max-height: 16rem;

    //@media only screen and (max-width: 600px) {
    //    font-size: 20px;
    //}
}

select {
    min-height: 50px;
    width: 100%;
    padding: 4px 2rem 4px 14px;
    margin-bottom: 8px;

    color: black;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;

    outline: 0;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 1px inset;
    border-radius: 4px;
    transition: all .2s ease;
    appearance: none;

    background-image: url($chevron-down);
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 24px 24px;

    &:hover {
        background-color: transparent;
    }
}

@import "breakpoints";

$nav_height: 84px;
$footer_height: 112px;
$section_padding: 3rem;

.hamburger__container {
    position: fixed;
    top: $nav_height;
    left: 0;
    width: 100%;
    height: calc(100% - $nav_height);
    z-index: 900;

    transition: all .25s ease;
    opacity: 0;
    pointer-events: none;
    background-color: var(--colour-white);

    &.show {
        transition: opacity 0s ease;
        opacity: 1;
        pointer-events: auto;
    }
}

.hamburger__body {
    display: flex;
    flex-direction: column;

    width: calc(100% - 32px);
    height: calc(100% - 24px);
    margin: 0 16px 24px 16px;
    border-radius: 24px;
    background-color: var(--colour-lemon);
    justify-content: center;

    > * {
        margin: 16px auto;
        text-align: center;
        max-width: 200px;
    }

    .button__link {
        width: 200px;
    }

    > *:first-child {
        margin-top: -24px;

        @media only screen and (min-width: $Laptop) {
            margin-top: -64px;
        }

        @media only screen and (min-width: $Laptop) and (max-height: 650px) {
            margin-top: 0;
        }
    }
}

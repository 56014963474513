button {
    display: grid;
    grid-auto-flow: column;
    grid-gap: .5rem;
    place-content: center;

    margin: 0;
    padding: 6px 14px;
    min-height: 40px;

    color: rgb(0, 0, 0);
    font-weight: 700;

    cursor: pointer;
    outline: none;
    background-color: rgb(182, 176, 132);
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 12px 0;
    transition: all .2s ease;
    white-space: nowrap;

    span {
        font-size: 18px;
        line-height: 24px;
        height: 20px;
        max-height: 20px;
    }

    &:hover {
        background-color: rgb(193, 188, 151);
    }

    &.accept-terms {
        min-height: 40px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-bottom: 8px;

        color: black;
        font-size: 20px;
        font-weight: 300;
        line-height: 22px;

        outline: 0;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 1px inset;
        border-radius: 4px;

        display: grid;
        grid-template-columns: min-content 128px min-content;
        text-align: left;

        span {
            all: unset;
            margin: auto 0;
        }

        &:hover {
            background-color: #b2ab7b;
        }

        .square {
            display: grid;
            place-content: center;
            height: 24px;
            width: 24px;

            font-size: 12px;
            font-family: sans-serif;

            border-width: 1px;
            border-style: solid;
            border-color: black;
            border-radius: 3px;

            background-color: #fdf3ae;
            transition: all .2s ease;
        }

        .checked {
            opacity: 0;
        }

        &.checked {
            box-shadow: rgba(0, 0, 0, 0.8) 0 0 0 2px inset;

            .checked {
                opacity: 1;
            }

            .square {
                color: #fdf3ae;
                background-color: black;
            }
        }
    }
}

@import "./mixins";

.combo-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content;

    input {
        text-overflow: ellipsis;
    }
}

.combo-input {

}

.combo-button {
    display: grid;
    place-content: center;
    margin: .5rem;

    button {
        @include form-button;
        > * {
            pointer-events: none;
        }
    }
}

.combo-list {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;

    .collapsable__body > div {
        background-color: var(--colour-white);
        max-height: 350px;
        overflow-y: scroll;

        > div {
            padding: .25rem .75rem;
        }

        border-radius: 0 0 6px 6px;
    }

    //background-color: #7c7754;
    //
    //width: 100%;
    //height: 48px;
    //opacity: 0;

    //&[data-open="true"] {
    //    opacity: 1;
    //}
}

.combo-list__item {
    cursor: pointer;

    &:hover {
        background-color: var(--colour-apricot);
    }

    &--static {
        pointer-events: none;
    }
}

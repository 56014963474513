a {
    font-size: 20px;
    color: #7c7754;
    transition: all .1s ease;

    &:hover {
        color: #545039;
        text-decoration: underline;
    }
}



@use "../shared/variables" as var;

button, .button {
    position: relative;
    z-index: 0;
    overflow: hidden;

    --button-background-loading-colour: black;

    &:disabled {
        pointer-events: none;
    }

    &[data-loading="true"] {
        cursor: default;
        pointer-events: none;

        &:before {
            opacity: 1;
        }
    }

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all .33s ease;

        background-image: linear-gradient(
                45deg,
                var(--button-background-loading-colour) 25%,
                transparent 25%,
                transparent 50%,
                var(--button-background-loading-colour) 50%,
                var(--button-background-loading-colour) 75%,
                transparent 75%,
                transparent
        );

        background-size: 2rem 2rem;
        animation: 1s linear infinite loading-stripes;

        @keyframes loading-stripes {
            0% {
                background-position: 2rem 0;
            }
            to {
                background-position: 0 0;
            }
        }
    }
}

button.form__submit, button.form__navigation {
    --button-font-colour: var(--colour-lemon);
    --button-background-colour: var(--colour-black);
    border-radius: .75rem;
    transition: all .2s ease;

    font-family: var(--font-ppeditorial);
    font-size: 1rem;
    line-height: 1.5;
    white-space: nowrap;

    padding: 1rem 1.75rem;

    p {
        all: unset;
        display: grid;
        place-content: center;

        height: 100%;

        margin-top: 1px;
        margin-bottom: -1px;
    }

    @media (hover: hover) {
        &:hover {
            --button-font-colour: var(--colour-black);
            --button-background-colour: var(--colour-apricot);
            --button-background-loading-colour: #f1cb7d;
        }
    }

    &:disabled {
        --button-font-colour: var(--colour-grey);
        --button-background-colour: var(--colour-black);
        --button-background-loading-colour: #343434;
    }

    &[data-loading="true"] {
        --button-font-colour: var(--colour-black);
        --button-background-colour: var(--colour-apricot);
        --button-background-loading-colour: #f6ce7e;
        //--button-background-loading-colour: #f1cb7d;
    }

    color: var(--button-font-colour);
    background-color: var(--button-background-colour);
}

button.form__submit {
    font-size: 1.5rem;
}

button.form__navigation {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: 35px 35px;

    .arrow {
        height: 24px;
        width: 35px;
        margin-bottom: -4px;

        &:first-child {
            transform: rotateY(180deg);
        }

        path {
            transition: all .2s ease;
            stroke: var(--button-font-colour); // #FFF5B0;
        }
    }
}

@media only screen and (width <= var.$sm) {
    button.form__navigation {
        place-content: center;
        border-radius: 0;

        &:disabled {
            opacity: 1;
            background-color: var(--colour-grey);
            color: black;
            .arrow path {
                stroke: var(--colour-black);
            }
        }
    }
}

@import "breakpoints";

.input--hidden {
    pointer-events: none;
    height: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    width: 100%;
    position: absolute;
}

.input__faux {
    pointer-events: none;
    height: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    width: 100%;
    position: absolute;

    &--relative {
        position: relative;
    }
}

.input__group {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 16px 0;

    @media only screen and (max-width: $Laptop) {
        justify-content: center;
    }

    // Emulates flex gap
    margin-right: -24px;

    > div, button {
        // Emulates flex gap
        margin-top: 0;
        margin-right: 24px;

        flex: 1 0 200px;
        min-width: 200px;
    }

    //> div:last-child {
    //    margin-bottom: 0;
    //}

    &.always {
        > div, button {
            flex: 1 0 140px;
            min-width: 140px;
        }
    }

    &--small {
        > div, button {
            font-size: 1rem;
            flex: 1 0 170px;
            min-width: 170px;
        }
    }

    &--space-between {
        justify-content: space-between;
    }

    @media only screen and (max-width: $Laptop) {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &--no-mt, &--no-my, &--mt-0, &--my-0 {
        margin-top: 0;
    }

    &--no-mb, &--no-my, &--mb-0, &--my-0 {
        margin-bottom: 0;
    }

    @media only screen and (max-width: $Mobile) {
        flex-direction: column;
        margin-right: 0;
        > div, button {
            flex: 1 auto;
            margin-right: 0;
        }
    }

    &--grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-gap: 8px 24px;

        > div, button {
            min-width: 0;
            margin: 0;
        }

        > input {
            position: absolute;
            bottom: 0;
        }

        margin: 0;
    }
}

.input__list {
    z-index: 3;
    position: absolute;
    width: calc(100% + 2px);
    background-color: white;
    margin-left: -1px;

    border-radius: 0 0 12px 12px;
    border: 1px solid var(--colour-grey);
    transition: all .2s ease,
                opacity .1s ease;

    top: 46px;
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    &--show {
        overflow-y: scroll;
        max-height: 200px;
        opacity: 1;
    }

    > div {
        cursor: pointer;
        transition: all .1s ease;
        position: relative;
        padding: 6px 12px 3px;
        line-height: 24px;
        font-family: var(--font-franklin);
        font-weight: 400;

        > span {
            display: block;
        }

        > span:last-child {
            font-weight: 300;
        }
    }

    > div:nth-child(2n) {
        background-color: var(--colour-light-grey);
    }

    > div:hover {
        background-color: var(--colour-list-hover);
    }
}

.input__prefix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 0 0 0;
    transition: all .25s ease;

    &--phone {
        position: relative;
        min-width: 42px; // 60px
        max-width: 42px; // 60px
        border-right: 1px solid var(--colour-grey);
        cursor: pointer;

        background-image: url("/static/icons/flags/round/norway.svg");
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: 13px 50%;

        //&::after {
        //    content: "";
        //    position: absolute;
        //    height: 20px;
        //    width: 20px;
        //    right: 9px;
        //
        //    background-image: url("/static/icons/chevron-down.svg");
        //    background-repeat: no-repeat;
        //    background-size: 100% 100%;
        //}
    }
}

.input__container {
    position: relative;
    height: 48px;
    width: 100%;
    transition: all .25s ease,
                border-radius .15s ease;

    background-color: var(--colour-white);
    border: 1px solid var(--colour-grey);
    box-sizing: border-box;
    border-radius: 12px;
    margin: 24px 0;

    &.open-list {
        border-radius: 12px 12px 0 0;
    }

    &:not(input:-webkit-autofill) {

    }

    &:focus-within {
        border: 1px solid var(--colour-black);

        .input__prefix {
            border-color: var(--colour-black);
        }

        .input__list {
            border-color: var(--colour-black);
        }
    }

    &--default {
        padding: 0 12px 0 4px;
    }

    &--phone {

    }

    &--searchable {
        .loader__container {
            position: absolute;
            right: 24px;
            top: 23px;
        }
    }

    &--default, &--phone {
        //overflow: hidden;
    }

    &--default, &--phone, &--searchable {
        display: flex;
        flex-direction: row;
        justify-content: left;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
        }

        label, input {
            font-family: var(--font-ppeditorial);
            font-size: 1rem;
            padding: 3px 0 0 12px;
            white-space: nowrap;
        }

        label {
            display: flex;
            align-items: center;
            line-height: 26px;
            transition: all .25s ease,
                        background-color 0s ease;
            background-color: transparent;
            top: 0;
        }

        input {
            display: flex;
            flex: 1;
            background-color: transparent;
            outline: none;
            border: none;
        }

        @media only screen and (max-width: $Laptop) {
            margin: 16px 0;
            border-radius: 8px;
        }
    }

    &:focus-within, &--with-value {
        label {
            position: absolute;
            top: -12px;
            padding: 5px 6px 1px;
            margin-left: 6px;
            font-size: 12px;
            line-height: 12px;
            z-index: 1;

            color: white;
            background-color: black;
            border-radius: 6px;
        }
    }
}

.input__radio {
    &--small {
        .button {
            font-size: 14px;
        }
    }

    &--fit {
        .button {
            height: auto;
        }
    }
}

.select__container {
    position: relative;
    margin: 24px 0;

    @media only screen and (max-width: $Laptop) {
        margin: 16px 0;
        border-radius: 8px;
    }

    label {
        position: absolute;
        pointer-events: none;
        height: calc(100% - 4px);
        width: calc(100% - 32px);
        margin: 2px 16px;

        line-height: 44px;

        transition: all .25s ease,
                    background-color 0s ease;
        background-color: white;

        @media only screen and (max-width: $Laptop) {
            margin: 2px 24px 2px 8px;
            padding: 0 8px;
        }
    }

    &:focus-within, &--with-value {
        label {
            top: -12px;
            height: auto;
            width: auto;
            padding: 5px 6px 1px;
            margin: 0 10px;

            font-size: 12px;
            line-height: 12px;
            z-index: 1;

            color: white;
            background-color: black;
            border-radius: 6px;
        }
    }
}

.checkbox {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1rem;

    > div {
        display: grid;
        align-content: center;
    }

    label {
        margin: 6px 0 0;
        line-height: 1.1;
    }

    input {
        display: grid;
        place-content: center;
        width: 18px;
        height: 18px;

        appearance: none;
        cursor: pointer;
        transition: all .25s ease;

        background-color: #ffffff;
        border: 1px solid var(--colour-grey);
        border-radius: 4px;

        &:hover {
            background-color: #f6f6f6;
        }

        &:checked {
            background-color: #000;
            border: 2px solid #000;

            &::before {
                box-shadow: inset 1em 1em white;
            }
        }

        &::before {
            content: "";
            width: 18px;
            height: 18px;
            transform-origin: bottom left;
            clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%)
        }
    }
}

select {
    height: 48px;
    width: 100%;
    background-color: var(--colour-white);
    border: 1px solid var(--colour-grey);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 16px 0 12px;
    font-family: var(--font-ppeditorial);
    font-size: 1rem;

    &:focus-within {
        border: 1px solid var(--colour-black);
    }

    @media only screen and (max-width: $Laptop) {
        border-radius: 8px;
    }
}

textarea {
    width: 100%;
    background-color: var(--colour-white);
    border: 1px solid var(--colour-grey);
    box-sizing: border-box;
    border-radius: 12px;

    font-family: var(--font-ppeditorial);
    font-size: 1rem;

    min-height: 7rem;
    max-height: 15rem;
    resize: none;
    padding: 12px 16px;
}



@import "breakpoints";

@mixin position-helpers($start, $end, $p) {
    // Positions anything between the two sizes.
    @media only screen and (min-width: $start) and (max-width: $end) {
        [class~=#{"center" + $p}] {
            text-align: center;
        }

        [class~=#{"left" + $p}] {
            text-align: left;
        }

        [class~=#{"right" + $p}] {
            text-align: right;
        }
    }

    // Positions anything from the START size and up.
    @media only screen and (min-width: $start) {
        [class~=#{"center" + $p + "-up"}] {
            text-align: center;
        }

        [class~=#{"left" + $p + "-up"}] {
            text-align: left;
        }

        [class~=#{"right" + $p + "-up"}] {
            text-align: right;
        }
    }

    // Positions everything from the END size and down.
    @media only screen and (max-width: $end) {
        [class~=#{"center" + $p + "-down"}] {
            text-align: center;
        }

        [class~=#{"left" + $p + "-down"}] {
            text-align: left;
        }

        [class~=#{"right" + $p + "-down"}] {
            text-align: right;
        }
    }

    // Positions when outside of the two sizes.
    [class~=#{"center" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            text-align: center;
        }
    }

    [class~=#{"left" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            text-align: left;
        }
    }

    [class~=#{"right" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            text-align: right;
        }
    }
}

@mixin h1() {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    margin: 2rem 0;
}

@mixin h2() {
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);
    margin: 1.5rem 0;
}

@mixin h3() {
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);
    margin: 1rem 0;
}

@mixin h4() {
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
    margin: 1rem 0;
}

@mixin h5() {
    font-size: var(--font-size-h5);
    margin: 1rem 0;
}

@mixin h6() {
    font-size: var(--font-size-h6);
    margin: 1rem 0;
}

@mixin mimic($start, $end, $p) {
    // Mimics anything from the START size and up.
    @media only screen and (min-width: $start) {
        [class~=#{"h1m" + $p + "-up"}] {
            @include h1();
        }

        [class~=#{"h2m" + $p + "-up"}] {
            @include h2();
        }

        [class~=#{"h3m" + $p + "-up"}] {
            @include h3();
        }

        [class~=#{"h4m" + $p + "-up"}] {
            @include h4();
        }

        [class~=#{"h5m" + $p + "-up"}] {
            @include h5();
        }

        [class~=#{"h6m" + $p + "-up"}] {
            @include h6();

        }
    }

    // Mimics everything from the END size and down.
    @media only screen and (max-width: $end) {
        [class~=#{"h1m" + $p + "-down"}] {
            @include h1();
        }

        [class~=#{"h2m" + $p + "-down"}] {
            @include h2();
        }

        [class~=#{"h3m" + $p + "-down"}] {
            @include h3();
        }

        [class~=#{"h4m" + $p + "-down"}] {
            @include h4();
        }

        [class~=#{"h5m" + $p + "-down"}] {
            @include h5();
        }

        [class~=#{"h6m" + $p + "-down"}] {
            @include h6();

        }
    }
}

// Spacing based on situations
@include position-helpers("0px", "9999px", "");
@include position-helpers("0px", $Mobile, "-mobile");
@include position-helpers($Mobile, $Laptop, "-tablet");
@include position-helpers($Laptop, "9999px", "-desktop");

// Mimics based on situations
@include mimic("0px", $Mobile, "-mobile");
@include mimic($Mobile, $TabletSmall, "-xs");
@include mimic($TabletSmall, $Tablet, "-sm");
@include mimic($Tablet, $Laptop, "-md");
@include mimic($Laptop, $LaptopLarge, "-lg");
@include mimic($LaptopLarge, "9999px", "-desktop");

h1, h2, h3, h4, h5, h6, p, button, div, a {
    font-style: normal;
    font-weight: 400;

    // Sizes for the smallest devices and up
    --font-size-h1: 1.75rem;
    --line-height-h1: 2.25rem;
    --font-size-h2: 1.5rem;
    --line-height-h2: 2.25rem;
    --font-size-h3: 1.25rem;
    --line-height-h3: 1.75rem;
    --font-size-h4: 1rem;
    --line-height-h4: 1.5rem;
    --font-size-h5: 1.33rem;   // TODO: Not following specs
    --font-size-h6: 1.25rem;   // TODO: Not following specs
    --font-size-p: 1.1rem;     // TODO: Not following specs

    .title {
        &--small {
            --font-size-h1: 1.6rem;
            --line-height-h1: 2rem;
            --font-size-h2: 1.4rem;
            --line-height-h2: 2rem;
        }
    }

    // Sizes for small mobile devices and up
    @media only screen and (min-width: $MobileSmall) {

    }

    // Sizes for ordinary mobile devices and up
    @media only screen and (min-width: $Mobile) {
        --font-size-h1: 2.5rem;
        --line-height-h1: 3.5rem;
        --font-size-h2: 2rem;
        --line-height-h2: 2.5rem;
        --font-size-h3: 1.5rem;
        --line-height-h3: 2.25rem;
        --font-size-h4: 1.4rem;
        --line-height-h4: 2rem;
        //--font-size-h5: 1.33rem;   // TODO: Not following specs
        //--font-size-h6: 1.25rem;   // TODO: Not following specs
        --font-size-p: 1.25rem;
        --line-height-p: 1.75rem;
    }

    // Sizes for laptops and up
    @media only screen and (min-width: $Laptop) {
        --font-size-h1: 3.5rem;
        --line-height-h1: 4.5rem;
        --font-size-h2: 2.5rem;
        --line-height-h2: 3.5rem;
        --font-size-h3: 2rem;
        --line-height-h3: 2.5rem;
        --font-size-h4: 1.5rem;
        --line-height-h4: 2.25rem;
        //--font-size-h5: 1.33rem;     // TODO: Not following specs
        //--font-size-h6: 1.25rem;     // TODO: Not following specs
        --font-size-p: 1.25rem;
        --line-height-p: 1.75rem;

        .title {
            &--small {
                --font-size-h1: 3rem;
                --line-height-h1: 4rem;
                --font-size-h2: 2rem;
                --line-height-h2: 3rem;
            }
        }
    }
}

h1, .h1m {
    @include h1();
}

h2, .h2m {
    @include h2();
}

h3, .h3m {
    @include h3();
}

h3 {
    text-transform: uppercase;
    font-family: var(--font-franklin);
}

h4, .h4m {
    @include h4();
}

h5, .h5m {
    @include h5();
}

h6, .h6m {
    @include h6();
}

p {
    font-size: var(--font-size-p);
    line-height: var(--line-height-p);
    margin: 1rem 0;

    &.section {
        &__title {
            @media only screen and (max-width: $Laptop) {
                --font-size-p: 1.25rem;
            }
        }
    }
}

a {
    font-family: var(--font-ppeditorial);
    font-size: 1.125rem;
    text-decoration: none;
    color: var(--colour-black);

    > span {
        line-height: 38px;
        text-decoration: underline;
    }
}

*::placeholder {
    //transition: opacity .15s ease,
    //color .15s ease;
}

*:focus::placeholder {
    opacity: 0;
}

ul, ol {}
span {}
footer {}

@import "../mekle/breakpoints";

.stop-scrolling {
    height: 100%;
    overflow: hidden;

    &-hamburger {
        @media only screen and (max-width: $Laptop) {
            height: 100%;
            overflow: hidden;
        }
    }
}

@mixin hide-helper($start, $end, $p) {
    // Hides anything between the two sizes.
    @media only screen and (min-width: $start) and (max-width: $end) {
        [class~=#{"hide-" + $p}] {
            display: none !important;
        }
    }

    // Hides anything from the START size and up.
    @media only screen and (min-width: $start) {
        [class~=#{"hide-" + $p + "-up"}] {
            display: none !important;
        }
    }

    // Hides everything from the END size and down.
    @media only screen and (max-width: $end) {
        [class~=#{"hide-" + $p + "-down"}] {
            display: none !important;
        }
    }

    // Hides when outside of the two sizes.
    [class~=#{"show-" + $p}] {
        @media only screen and (max-width: $start), (min-width: $end) {
            display: none !important;
        }
    }
}

// Hiders based on situations
@include hide-helper("0px", $MobileSmall, "mobile-small");
@include hide-helper($MobileSmall, $Mobile, "mobile-large");
@include hide-helper("0px", $Mobile, "mobile");
@include hide-helper($Mobile, $Laptop, "tablet");
@include hide-helper($Laptop, "9999px", "desktop");

// Hiders based on breakpoints
@include hide-helper($Mobile, $TabletSmall, "xs");
@include hide-helper($TabletSmall, $Tablet, "sm");
@include hide-helper($Tablet, $Laptop, "md");
@include hide-helper($Laptop, $LaptopLarge, "lg");
@include hide-helper($LaptopLarge, $Desktop, "xl");
@include hide-helper($Desktop, $DesktopLarge, "hu");
@include hide-helper($DesktopLarge, "9999px", "ma");

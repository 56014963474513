nav {
    z-index: 1000;
    position: fixed;
    top: 0;

    width: 100%;
    height: var(--nav-height);
    padding: 24px;
    background-color: transparent;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .row--left {
        cursor: pointer;
        height: 32px;
        content: url("/static/logo/Mekle_Logo_Digital_Black.svg");
    }

    .row--right {}
}

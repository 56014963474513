@import "breakpoints";

button {
    background-color: transparent;
    border: none;
}

.button, button {
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-ppeditorial);
    cursor: pointer;
}

.button {
    display: inline-block;
    text-align: center;
    user-select: none;

    border-radius: 12px;
    padding: 12px 48px;
    margin: 8px 0;

    @media only screen and (max-width: $Tablet) {
        padding: 12px 24px;
        min-width: 150px;
    }

    &--small {
        padding: 8px 32px 6px;
        font-size: 18px;
        line-height: 20px;
        height: 32px;

        @media only screen and (max-width: $Tablet) {
            padding: 8px 16px 6px;
        }
    }

    &__toggle {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;
        height: 48px;
    }

    &__info, &--long, &__add {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;

        @media only screen and (max-width: $Laptop) {
            max-width: 300px;
        }

        @media only screen and (max-width: $Mobile) {
            max-width: 100%;
        }
    }

    &__info, &--long {
        height: 48px;
        padding: 8px 16px 8px 24px;

        @media only screen and (max-width: $Laptop) {
            height: auto;
            padding: 16px;
        }

        &--small {
            height: 40px;
            padding: 8px 16px 8px 16px;

            @media only screen and (max-width: $Laptop) {
                height: auto;
                padding: 8px;
            }
        }
    }

    &__link {
        padding: 8px 32px;
    }

    &__locale {
        padding: 8px;
        background-color: var(--colour-light-grey);
        color: var(--colour-dark-grey);

        .active {
            color: var(--colour-black);
        }
    }

    &__locale, &__link {
        cursor: pointer;
        user-select: none;
        height: 38px;
        border-radius: 12px;
    }

    &::after {
        transition: all .2s ease;
    }
}

.button__toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 1rem;
    line-height: 1rem;
    font-family: var(--font-ppeditorial);
    padding: 18px 55px 14px 15px;
    text-align: left;

    > div:nth-child(1) {
        min-width: 80px;
    }

    > div:nth-child(2) {
        margin-left: 12px;
    }

    &::after {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        right: 20px;
        top: calc(50% - 10px);
        //margin-left: 16px;

        background-image: url("/static/icons/unchecked.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    &.checked::after {
        background-image: url("/static/icons/checked.svg");
    }
}

.button__add, .button__info {
    font-family: var(--font-franklin);
    text-transform: uppercase;
}

.button__add {
    &::after {
        content: "";
        position: absolute;
        right: 32px;
        top: 12px;
        width: 50px;
        height: 24px;
        align-content: center;

        background-image: url("/static/icons/plus.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.button__info {
    font-family: var(--font-franklin);
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
    text-transform: uppercase;

    @media only screen and (max-width: $Laptop) {
        font-size: var(--font-size-h3);
        line-height: var(--line-height-h3);
    }

    @media only screen and (max-width: $Laptop) {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    &--small {
        font-size: var(--font-size-h5);
        line-height: 1.25;

        @media only screen and (max-width: $Laptop) {
            font-size: var(--font-size-h3);
        }
    }

    .button_header {
        display: flex;
    }

    .button__body {
        position: relative;

        > div {
            display: inline-block;
            opacity: 0;
            pointer-events: none;
            position: absolute;

            transition: all .25s ease;
            text-align: left;
            text-transform: none;
            font-family: var(--font-ppeditorial);
            font-size: 14px;
            line-height: 18px;
            color: var(--colour-white);
            background-color: var(--colour-black);
            width: 200px;
            padding: 24px 16px;
            border-radius: 12px;

            top: -110px;
            left: calc(28px - 100px);

            @media only screen and (max-width: $Tablet) {
                padding: 12px 8px;
            }

            @media only screen and (max-width: $Laptop) {
                opacity: 1;
                position: static;
                display: block;
                color: var(--colour-black);
                background-color: transparent;
                padding: 16px 0 0;
            }

            &--small {

            }
        }

        @media only screen and (min-width: $Laptop) {
            &::after {
                content: "";
                position: absolute;
                height: 24px;
                width: 24px;
                top: 6px;
                margin-left: 16px;
                transition: all .2s ease;

                background-image: url("/static/icons/info.svg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            &:hover > div {
                opacity: 1;
            }

            &:hover::after {
                background-image: url("/static/icons/info-active.svg");
            }
        }
    }

    &--exposed {
        display: flex;
        flex-direction: column;
        text-align: left;
        height: auto;

        .button__body {
            > div {
                opacity: 1;
                position: static;
                display: block;
                color: var(--colour-black);
                background-color: transparent;
                padding: 16px 16px 16px 0;
                font-size: 18px;
                line-height: 24px;
                width: 100%;

                @media only screen and (max-width: $Laptop) {
                    font-size: 14px;
                    line-height: 18px;
                    padding: 16px 0 0;
                }
            }

            &::after {
                background-image: none;
            }

            &:hover::after {
                background-image: none;
            }
        }
    }

    &--small {
        .button__body {
            > div {
                top: -72px;
            }

            &::after {
                top: 4px;
                height: 20px;
                width: 20px;
            }
        }
    }

    &:not(&--small) {
        &::after {
            content: "";
            position: absolute;
            right: 32px;
            top: 12px;
            width: 50px;
            height: 24px;
            align-content: center;

            background-image: url("/static/icons/arrow.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            @media only screen and (max-width: $Laptop) {
                top: 20px;
                height: 20px;
            }
        }

        &:hover::after {
            right: 24px;
        }
    }
}

@mixin form-button {
    display: grid;
    place-content: center;

    width: 24px;
    height: 24px;
    padding: 0;

    background-color: var(--colour-apricot);
    border-radius: 12px;

    &, svg path {
        transition: all 0.2s ease;
    }

    &:hover {
        background-color: var(--colour-black);

        svg:not(.no-fill) path {
            fill: var(--colour-white);
        }

        svg.no-fill path {
            stroke: var(--colour-white);
        }
    }
}

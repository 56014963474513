@use "../../shared/variables" as var;
@import "mixins";

.form__file {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: calc(44px + 2rem);

    background-color: var(--colour-apricot);
    border-radius: .75rem;

    > div {
        display: grid;
        grid-template-columns: min-content min-content;
        place-content: center;
        width: 100%;
        height: 100%;

        font-size: 16px;
        font-family: var(--font-ppeditorial);
        white-space: nowrap;

        div {
            display: grid;
            place-content: center;
        }
    }

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        opacity: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &::file-selector-button {
            pointer-events: none;
        }
    }

    @media only screen and (max-width: var.$md) {
        max-width: 100%;
    }
}
.form__files {
    display: grid;
    grid-gap: .75rem;

    > div {
        display: grid;
        align-content: center;
        width: 100%;

        padding: 9px 20px;
        background-color: var(--colour-white);
        border-radius: 12px;

        line-height: 24px;
        font-size: 1rem;
        font-family: var(--font-ppeditorial);

        > div {
            display: grid;
            grid-template-columns: 1fr min-content min-content;
            grid-gap: 1rem;
            width: 100%;
        }
    }

    button {
        @include form-button;
    }
}

$layoutSizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16;
$borderSizes: 0, 5, 10;

.transition-25 {
    transition: all .25s ease;
}

@mixin visibility_layout($prefix: "") {
    .#{$prefix}hidden {
        opacity: 0;
        pointer-events: none;

        &:not(&--partial), &:not(&--hard) {
            display: grid;
            grid-template-rows: repeat(10, 0fr);

            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;

            * {
                min-height: 0;
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &--hard {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            display: none;
        }

        &--partial {
            opacity: .2;
            pointer-events: auto;

            .#{$prefix}hidden--partial {
                opacity: 1;
            }
        }
    }

    .#{$prefix}show {
        opacity: 1;
        pointer-events: auto;
    }
}

@mixin layout($prefix: "") {
    .#{$prefix}my-a {
        margin-top: auto;
        margin-bottom: auto;
    }
    .#{$prefix}mx-a {
        margin-left: auto;
        margin-right: auto;
    }

    @each $size in $layoutSizes {
        .#{$prefix}m-#{$size} {
            margin: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}mt-#{$size} {
            margin-top: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}mb-#{$size} {
            margin-bottom: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}ml#{$size} {
            margin-left: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}mr-#{$size} {
            margin-right: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}my-#{$size} {
            margin-top: calc(1rem * #{$size} / 2);
            margin-bottom: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}mx-#{$size} {
            margin-left: calc(1rem * #{$size} / 2);
            margin-right: calc(1rem * #{$size} / 2);
        }
        .#{$prefix}p-#{$size} {
            padding: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}pt-#{$size} {
            padding-top: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}pb-#{$size} {
            padding-bottom: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}pl-#{$size} {
            padding-left: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}pr-#{$size} {
            padding-right: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}py-#{$size} {
            padding-top: calc(1rem * #{$size} / 2);
            padding-bottom: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}px-#{$size} {
            padding-left: calc(1rem * #{$size} / 2);
            padding-right: calc(1rem * #{$size} / 2);
        }

        .#{$prefix}gg-#{$size} {
            grid-gap: calc(1rem * #{$size} / 2) !important;
        }
    }

    @each $size in $borderSizes {
        .#{$prefix}br-#{$size} {
            border-radius: #{$size}px;
        }
    }
}

@include layout();
@include visibility_layout();

@media only screen and (width > 1000px) {
    @include layout("lg-");
    @include visibility_layout("lg-");
}

@media only screen and (width <= 1000px) {
    @include layout("md-");
    @include visibility_layout("md-");
}

@media only screen and (width <= 600px) {
    @include layout("sm-");
    @include visibility_layout("sm-");
}

@media only screen and (width <= 380px) {
    @include visibility_layout("xs-");
}

@import "../../mekle/breakpoints";

input {
    display: block;
    width: 100%;
    padding: 0 0 8px;

    color: black;
    font-size: 30px;
    line-height: unset;

    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px;
    -webkit-appearance: none;

    background-color: transparent;
    transition: all .2s ease;

    &:focus {
        box-shadow: black 0 2px;
    }

    &::placeholder {
        font-weight: 300;
        color: #b2ab7b;
    }

    @media only screen and (max-width: $Mobile) {
        font-size: 24px;
    }
}

#__contested-claims-v1 {
    @import "../../shared/helpers"; // TODO
    @import "../../shared/layout";
    @import "../shared/loading";
    @import "../shared/footer";
    @import "../shared/nav";
    @import "keyframes";
    @import "typography";
    @import "buttons";
    @import "input";
    @import "form";
    @import "questions";

    font-family: "Frank Ruhl Libre", sans-serif;
    background-color: black;

    main {
        position: relative;
        display: grid;

        height: 100vh;
        max-height: 100vh;
        padding: 20% 40px 25%;

        background-color: rgb(253, 243, 174);
        border-radius: 12px;

        @media only screen and (max-width: var(--bp-xs)) {
            padding: 70% 40px 50%;
        }

        @media only screen and (max-width: 380px) {
            padding: 50% 40px 50%;
        }
    }
}

.loading--help {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 50;
    display: grid;
    place-content: center;
    top: 0;
    left: 0;

    transition: all .2s ease;

    &.loading--out {
        pointer-events: none;
        opacity: 0;
    }

    .loading--inner {
        position: relative;
    }

    .loading--bar {
        height: auto;
        margin-top: 16px;

        &-0 {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-1 {
            width: 160px;
        }

        &-2 {
            width: 100%;
            height: 4px;
            border-radius: 4px;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.3);
            position: relative;
        }

        &-9 {
            height: 100%;
            background-color: rgb(0, 0, 0);
            border-radius: 4px;
            transition: all 0.4s ease 0s;
            width: 100%;
            animation: 1.3s linear 0s infinite normal none running running;
            transform-origin: 0% 50%;

            @keyframes running {
                0% {
                    transform: translateX(0px) scaleX(0);
                }
                10% {
                    transform: translateX(0px) scaleX(0.2);
                }
                40% {
                    transform: translateX(0px) scaleX(0.7);
                }
                60% {
                    transform: translateX(60%) scaleX(0.4);
                }
                100% {
                    transform: translateX(100%) scaleX(0.2);
                }
            }
        }
    }

    &.out {
        animation: 400ms ease-in-out 400ms 1 forwards fadeOut;
        opacity: 1;
    }
}

$sm: 600px;
$md: 800px;
$lg: 1000px;

:root {
    // Colours
    --colour-white: #ffffff;
    --colour-black: #000000;
    --colour-light-grey: #f7f7f7;
    --colour-grey: #cccccc;
    --colour-dark-grey: #636363;
    --medium-grey-1: #888888;

    --colour-lemon: #fff5b0;
    --colour-apricot: #ffda8f;
    --colour-peach: #ffca9f;

    --colour-list-hover: #f3f3f3;

    // Fonts
    --font-ppeditorial: "PP Editorial New", sans-serif;
    --font-franklin: FranklinGothicCondensed, sans-serif;

    // Miscellaneous
    --nav-height: 84px;
    --footer--height: 112px;

    // Breakpoints
    --bp-ti: 340px;     // ti, tiny
    --bp-xs: 600px;     // xs, extra small
    --bp-sm: 800px;     // sm, small
    --bp-md: 1000px;    // md, medium
    --bp-lg: 1200px;    // lg, large
    --bp-xl: 1400px;    // xl, extra large
    --bp-hu: 1600px;    // hu, huge
    --bp-ma: 2000px;    // ma, massive
}

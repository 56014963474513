.checkmark__container {
    display: flex;
    flex-direction: row;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: check_stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__circle.error {
    stroke: #c14242;
}

.checkmark {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0 12px;
    box-shadow: inset 0 0 0 #7ac142;
    animation: check_fill .4s ease-in-out .4s forwards, check_scale .3s ease-in-out .9s both;
}

.checkmark.error {
    box-shadow: inset 0 0 0 #c14242;
    animation: check_fill_red .4s ease-in-out .4s forwards, check_scale .3s ease-in-out .9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: check_stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes check_stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes check_scale {
    0%, 100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes check_fill {
    100% {
        box-shadow: inset 0 0 0 30px #7ac142;
    }
}
@keyframes check_fill_red {
    100% {
        box-shadow: inset 0 0 0 30px #c14242;
    }
}

@import "breakpoints";

.card__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // Emulates flex gap
    margin-right: -24px;

    > div {
        // Emulates flex gap
        margin-top: 0;
        margin-right: 24px;

        flex: 1 0 200px;
        min-width: 200px;
    }
}

.card {
    position: relative;
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: left;

    width: 100%;
    border-radius: 12px;
    padding: 12px 24px;
    margin: 8px 0;

    &--apricot {
        background-color: var(--colour-apricot);
    }

    &--peach {
        background-color: var(--colour-peach);
    }

    &--clickable {
        cursor: pointer;
    }

    &__delete {
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;
        width: 68px;
        background-image: url("/static/icons/delete.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;

        &:hover {
            background-image: url("/static/icons/delete-open.svg");
        }
    }
}

.filecard__container {
    display: flex;
    flex-wrap: wrap;
    transition: all .15s ease;
    position: relative;

    box-sizing: border-box;

    margin: auto;
    max-width: 800px;
    min-width: 300px;
    min-height: 116px;

    @media only screen and (max-width: $Laptop) {
        min-width: 250px;
        min-height: 125px;
    }

    background-color: white;
    border: 1px solid var(--colour-grey);
    border-radius: 12px;
    padding: 8px;

    &--uploading {
        min-height: 100px;
    }

    &--empty {
        max-width: 500px;
        border: 2px dashed var(--colour-black);
        border-radius: 16px;

        button {
            margin: 12px auto;
            width: auto !important;

            @media only screen and (max-width: $Laptop) {
                margin: auto;
            }
        }

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            cursor: pointer;
            opacity: 0;
            margin: 0;
        }

        &--valid {
            background-color: rgba(69,175,184,0.25);
        }

        &--invalid {
            background-color: rgba(243,136,136,0.25);
        }
    }

    div {
        flex-basis: 100%;
        flex-grow: 1;
        padding: 0 8px;
    }

    textarea {
        min-height: 4rem;
    }

    &--disappear {
        animation-duration: .15s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: disappear;

        @keyframes disappear {
            0% {
                opacity: 1;
                transform: translateX(0);
            }
            100% {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }
}

.filecard__column {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-basis: 200px;
    flex-grow: 1;
    padding: 0 8px;
    height: 116px;

    > *:last-child {
        position: absolute;
        transform: translateY(36px);
        width: calc(100% - 16px);
    }
}

// What did I mean by 'original'?
.filecard--original {
    padding: 8px;
    border-radius: 8px;
    background-color: #D7DFE9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 44px;
    margin: 5px auto 20px;
    width: calc(100% - 32px) !important;
}

.filecard__button--remove {
    position: absolute;
    top: -16px;
    right: -16px;
    //background: url(${RemoveSVG}) no-repeat center;
    background-size: 100% 100%;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.background {
    &--black {
        background-color: var(--colour-black);
        color: var(--colour-white);
    }

    &--white {
        color: var(--colour-black);
        background-color: var(--colour-white);
    }

    &--light-grey {
        background-color: var(--colour-light-grey);
        color: var(--colour-black);
    }

    &--apricot {
        background-color: var(--colour-apricot);
        color: var(--colour-black);
    }

    &--peach {
        background-color: var(--colour-peach);
        color: var(--colour-black);
    }

    &--lemon {
        background-color: var(--colour-lemon);
        color: var(--colour-black);
    }
}

@use "../shared/variables" as var;

$layoutSizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15;
$borderSizes: 0, 5, 10;
$conditionals: "", "!";

.transition-25 {
    transition: all .25s ease;
}

@mixin layout($prefix: "", $conditional: "") {
    $important: if($conditional == "\\!", "!important", "");

    .#{$prefix}hidden {
        opacity: 0;
        pointer-events: none;

        &:not(&--partial), &:not(&--hard) {
            display: grid;
            grid-template-rows: repeat(10, 0fr);

            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;

            * {
                min-height: 0;
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &--hard {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            display: none !important;
        }

        &--partial {
            opacity: .2;
            pointer-events: none;

            .#{$prefix}hidden--partial {
                opacity: 1;
            }
        }
    }

    .#{$prefix}show {
        opacity: 1;
        pointer-events: auto;
    }

    .#{$prefix}my-a {
        margin-top: auto;
        margin-bottom: auto;
    }
    .#{$prefix}mx-a {
        margin-left: auto;
        margin-right: auto;
    }

    .#{$prefix}group {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

        &--compact {
            grid-template-columns: repeat(auto-fit, minmax(100px, min-content));
        }

        &--flex {
            display: flex;
            flex-wrap: wrap;

            > * {
                flex: 0 1 auto;
            }
        }
    }

    .#{$prefix}stretch {
        width: 100%;
    }

    .#{$prefix}flex--grow {
        flex: 1 1 0;
    }

    .#{$prefix}justify {
        &--space-between {
            place-content: space-between;
        }
    }

    .#{$conditional}#{$prefix}min-w-none {
        min-width: none #{$important};
    }

    .#{$conditional}#{$prefix}max-w-none {
        max-width: none #{$important};
    }

    @each $size in $layoutSizes {
        .#{$conditional}#{$prefix}min-w-#{$size} {
            min-width: calc(1rem * #{$size} * 2) #{$important};
        }

        .#{$conditional}#{$prefix}max-w-#{$size} {
            max-width: calc(1rem * #{$size} * 2) #{$important};
        }

        .#{$conditional}#{$prefix}m-#{$size} {
            margin: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}mt-#{$size} {
            margin-top: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}mb-#{$size} {
            margin-bottom: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}ml-#{$size} {
            margin-left: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}mr-#{$size} {
            margin-right: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}my-#{$size} {
            margin-top: calc(1rem * #{$size} / 2) #{$important};
            margin-bottom: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}mx-#{$size} {
            margin-left: calc(1rem * #{$size} / 2) #{$important};
            margin-right: calc(1rem * #{$size} / 2) #{$important};
        }
        .#{$conditional}#{$prefix}mx-#{$size} {
            margin-left: calc(1rem * #{$size} / 2) #{$important};
            margin-right: calc(1rem * #{$size} / 2) #{$important};
        }
        .#{$conditional}#{$prefix}p-#{$size} {
            padding: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}pt-#{$size} {
            padding-top: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}pb-#{$size} {
            padding-bottom: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}pl-#{$size} {
            padding-left: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}pr-#{$size} {
            padding-right: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}py-#{$size} {
            padding-top: calc(1rem * #{$size} / 2) #{$important};
            padding-bottom: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}px-#{$size} {
            padding-left: calc(1rem * #{$size} / 2) #{$important};
            padding-right: calc(1rem * #{$size} / 2) #{$important};
        }

        .#{$conditional}#{$prefix}gg-#{$size} {
            grid-gap: calc(1rem * #{$size} / 2) !important;
        }
    }

    @each $size in $borderSizes {
        .#{$prefix}br-#{$size} {
            border-radius: #{$size}px;
        }
    }
}

@include layout("");
@include layout("", "\\!");

@media only screen and (width > var.$lg) {
    @include layout("lg-");
    @include layout("lg-", "\\!");
}

@media only screen and (width > var.$sm) and (width <= var.$lg) {
    @include layout("md-");
    @include layout("md-", "\\!");
}

@media only screen and (width <= var.$sm) {
    @include layout("sm-");
    @include layout("sm-", "\\!");
}

@import "breakpoints";

.funnel__title {
    font-size: var(--font-size-h2);
    line-height: var(--font-size-h2);

    @media only screen and (min-width: $Laptop) {
        font-size: var(--font-size-h3);
        line-height: var(--line-height-h3);
    }
}

.funnel {
    &__restart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        z-index: 999;
        opacity: 0;
        transition: all .25s ease;
        background-color: rgba(0, 0, 0, 0.25);

        &.show {
            pointer-events: auto;
            opacity: 1;
        }
    }

    &__container {
        z-index: 999;

        margin: auto;
        border-radius: 24px;
        padding: 16px 24px;
        color: var(--colour-white);
        background-color: var(--colour-black);

        &.show {
            animation-duration: .25s;
            animation-direction: normal;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            animation-name: slide-up;

            @keyframes slide-up {
                0% {
                    transform: translateY(25px);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }
    }
}

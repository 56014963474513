.form__navigation {
    position: fixed;
    bottom: 0;
    left: 25%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
    padding: 2rem 0;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: -50%;
        height: 100%;
        width: 100vw;
        background-color: white;
        z-index: -1;
    }

    &::after {
        position: absolute;
        content: "";
        top: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 10%,
            rgba(0, 0, 0, 1) 90%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    @media only screen and (max-width: 1000px) {
        width: 100%;
        //bottom: calc(2rem + 48px);
        bottom: 0;
        left: 0;
        padding: 1rem 0;

        &:before {
            left: 0;
            width: 100%;
        }

        &::after {
            background: black;
        }
    }

    @media only screen and (max-width: 375px) {
        bottom: 0;
    }
}

.form__navigation button {
    width: 150px;
    height: 60px;
    margin: auto;

    transition: all .25s ease;
    border: solid 2px black;
    border-radius: 5px;
    background-color: transparent;

    line-height: 60px;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-ppeditorial);
    color: black;

    &:disabled {
        border-color: transparent;
        color: rgba(0, 0, 0, 0.3);
    }

    &.submit {
        color: white;
        background-color: black;
    }

    @media only screen and (max-width: 1000px) {
        line-height: 48px;
        height: 48px;
    }
}

.booleans {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.booleans button {
    width: 100px;
    height: 48px;
    position: relative;
    margin: auto;

    transition: all .25s ease;
    border: solid 2px black;
    border-radius: 5px;
    background-color: transparent;

    line-height: 48px;
    font-size: 20px;
    font-weight: 400;
    font-family: var(--font-ppeditorial);
    color: black;

    &::after, &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        transition: all .25s ease;
    }

    //&::after {
    //    width: 10px;
    //    height: 10px;
    //    left: -17px;
    //}

    &::before {
        border: 2px solid black;
        background-color: white;
        width: 15px;
        height: 15px;
        left: -30px;
    }

    &.checked {
        &::before {
            background-color: black;
        }
    }
}

.tooltip {
    display: inline-block;
    position: relative;

    svg {
        position: absolute;
        bottom: 0;
        transform: translate(0, 5px);
    }

    div {
        position: absolute;
        margin: .75rem 0;
        top: 7.5px;
        left: 12.5px;
        width: 400px;
        padding: 1.5rem 1rem;
        z-index: 99;

        color: var(--colour-white);
        background-color: var(--colour-black);
        pointer-events: none;
        border-radius: .75rem;
        opacity: 0;

        font-size: 1rem;
        font-family: var(--font-ppeditorial);
        line-height: 1.5;
        text-transform: none;
    }

    div::after {
        position: absolute;
        content: "";
        top: -12.5px;
        left: 0;

        border-width: 0 25px 25px 0;
        border-style: solid;
        border-color: transparent transparent black transparent;
    }

    &:hover {
        div {
            opacity: 1;
            pointer-events: auto;
        }
    }

    @media only screen and (max-width: 600px) {
        position: unset;
        display: grid;
        place-content: center;

        svg {
            position: relative;
            transform: translate(0);
        }

        div {
            top: 100%;
            width: calc(100% - 1rem);
        }
    }
}

nav {
    z-index: 1000;
    position: fixed;
    top: 0;

    width: 100%;
    --nav-height: 102px;
    height: var(--nav-height);
    padding: 35px 30px;
    background-color: white;

    .row {
        display: grid;
        grid-template-columns: min-content 1fr;
        //display: flex;
        //flex-direction: row;
        //justify-content: space-between;
    }

    .row--left {
        cursor: pointer;
        height: 32px;
        content: url("/static/logo/Mekle_Logo_Digital_Black.svg");
    }

    .row--right {
        display: flex;
        gap: 2rem;
        justify-content: flex-end;
    }

    .nav__buttons {
        div, a {
            display: grid;
            place-content: center;

            cursor: pointer;
            transition: all .2s ease;

            font-size: 18px;
            line-height: 18px;
            text-decoration: underline;
            padding: 8px 0 6px 0;
            color: var(--colour-black);

            &:hover {
                text-decoration: none;
            }
        }

        .nav__button {
            &--language, &--cta {
                text-decoration: none;
                border-radius: 12px;
            }

            &--language {
                font-size: 14px;
                background-color: var(--colour-light-grey);
                padding: 8px 12px 6px 12px;
            }

            &--cta {
                background-color: var(--colour-lemon);
                padding: 8px 32px 6px 32px;

                &:hover {
                    color: var(--colour-white);
                    background-color: var(--colour-black);
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .row--right {
            display: none;
        }
    }

    @media only screen and (max-width: 600px) {
        padding: 35px 16px;
    }
}

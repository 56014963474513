.questions {
    position: relative;

    .scroll-right-in, .scroll-right-out, .scroll-left-in, .scroll-left-out {
        animation-duration: .25s;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    .scroll-right-in {
        animation-name: scroll-right-in;
    }

    .scroll-right-out {
        animation-name: scroll-right-out;
    }

    .scroll-left-in {
        animation-name: scroll-left-in;
    }

    .scroll-left-out {
        animation-name: scroll-left-out;
    }

    .scroll-left-out, .scroll-right-out {
        pointer-events: none;
        position: absolute;
        top: 0;
    }
}

.questions__section {
    //position: absolute;
    width: 100%;

    //> *:first-child {
    //    margin-top: 32px !important;
    //}

    > *:first-child {
        margin-top: 0 !important;
    }

    padding-bottom: 10rem;
}

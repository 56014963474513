@use "../shared/variables" as var;
@import "../shared/extensions";

form {
    margin: auto;
}

.form__pages {
    @extend %grid-layout;

    position: relative;
    place-content: center;
}

.form__page {
    transition: all .33s ease;
    position: absolute;
    width: 100%;

    > * {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
    }

    &--disabled {
        pointer-events: none;
        opacity: 0;
        transition: all 0s ease;
    }
}

@media only screen and (width <= var.$sm) {
    .form__navigations {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
    }
}

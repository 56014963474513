@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "../mekle/breakpoints";

.style--help {
    @import "./help/questions";
    @import "./help/loading";
    @import "./help/input";
    @import "./help/button";
    @import "./help/form";
}

.style--help {
    all: unset;

    font-family: "Frank Ruhl Libre", sans-serif;
    background-color: black;

    a {
        font-size: 20px;
        color: #7c7754;
        transition: all .1s ease;

        &:hover {
            color: #545039;
            text-decoration: underline;
        }
    }

    .background {
        position: relative;
        display: grid;

        height: 100vh;
        max-height: 100vh;
        width: 100vw;
        padding: 20% 40px 25%;

        background-color: rgb(253, 243, 174);
        border-radius: 12px;

        @media only screen and (max-width: $Mobile) {
            padding: 70% 40px 50%;
        }

        @media only screen and (max-width: 380px) {
            padding: 50% 40px 50%;
        }
    }
}

.nav--help {
    background-color: transparent !important;
}

.footer--help {
    background-color: transparent !important;
}

.style--help {
    box-sizing: border-box;

    * {
        box-sizing: border-box;
        font-family: "Frank Ruhl Libre", sans-serif;
    }
}

#__v2 {
    @import "../shared/extensions";
    @import "../shared/collapsable";
    @import "./nav";
    @import "./layout";
    @import "./sections";
    @import "./typography";
    @import "./form";
    @import "./inputs";
    @import "./buttons";
    @import "./tooltip";
    @import "./media";
    @import "./svgs";

    main {
        @extend %grid-layout;
        padding: 102px 32px 0 32px;

        @media only screen and (max-width: 600px) {
            padding: 102px 16px 0 16px;
        }
    }

    div {
        transition: all .2s ease;

        &.disabled {
            opacity: .5;

            * {
                pointer-events: none;
            }
        }
    }

    nav {
        @media only screen and (max-width: 1000px) {
            background-color: white;
        }
    }
}

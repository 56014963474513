@import "breakpoints";

.form__navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form__button {
    &--hidden {
        pointer-events: none;
        opacity: 0;
    }
}

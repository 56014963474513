@import "breakpoints";

$spacer: 1rem;

@mixin margin($p) {
    [class~=#{"mt-" + $p + "0"}] { margin-top: ($spacer * 0) }
    [class~=#{"mt-" + $p + "1"}] { margin-top: ($spacer * .25) }
    [class~=#{"mt-" + $p + "2"}] { margin-top: ($spacer * .5) }
    [class~=#{"mt-" + $p + "3"}] { margin-top: ($spacer) }
    [class~=#{"mt-" + $p + "4"}] { margin-top: ($spacer * 1.5) }
    [class~=#{"mt-" + $p + "5"}] { margin-top: ($spacer * 2) }
    [class~=#{"mt-" + $p + "6"}] { margin-top: ($spacer * 3) }
    [class~=#{"mt-" + $p + "7"}] { margin-top: ($spacer * 4) }
    [class~=#{"mt-" + $p + "8"}] { margin-top: ($spacer * 5) }

    [class~=#{"mb-" + $p + "0"}] { margin-bottom: ($spacer * 0) }
    [class~=#{"mb-" + $p + "1"}] { margin-bottom: ($spacer * .25) }
    [class~=#{"mb-" + $p + "2"}] { margin-bottom: ($spacer * .5) }
    [class~=#{"mb-" + $p + "3"}] { margin-bottom: ($spacer) }
    [class~=#{"mb-" + $p + "4"}] { margin-bottom: ($spacer * 1.5) }
    [class~=#{"mb-" + $p + "5"}] { margin-bottom: ($spacer * 2) }
    [class~=#{"mb-" + $p + "6"}] { margin-bottom: ($spacer * 3) }
    [class~=#{"mb-" + $p + "7"}] { margin-bottom: ($spacer * 4) }
    [class~=#{"mb-" + $p + "8"}] { margin-bottom: ($spacer * 5) }

    [class~=#{"ml-" + $p + "0"}] { margin-left: ($spacer * 0) }
    [class~=#{"ml-" + $p + "1"}] { margin-left: ($spacer * .25) }
    [class~=#{"ml-" + $p + "2"}] { margin-left: ($spacer * .5) }
    [class~=#{"ml-" + $p + "3"}] { margin-left: ($spacer) }
    [class~=#{"ml-" + $p + "4"}] { margin-left: ($spacer * 1.5) }
    [class~=#{"ml-" + $p + "5"}] { margin-left: ($spacer * 2) }
    [class~=#{"ml-" + $p + "6"}] { margin-left: ($spacer * 3) }
    [class~=#{"ml-" + $p + "7"}] { margin-left: ($spacer * 4) }
    [class~=#{"ml-" + $p + "8"}] { margin-left: ($spacer * 5) }

    [class~=#{"mr-" + $p + "0"}] { margin-right: ($spacer * 0) }
    [class~=#{"mr-" + $p + "1"}] { margin-right: ($spacer * .25) }
    [class~=#{"mr-" + $p + "2"}] { margin-right: ($spacer * .5) }
    [class~=#{"mr-" + $p + "3"}] { margin-right: ($spacer) }
    [class~=#{"mr-" + $p + "4"}] { margin-right: ($spacer * 1.5) }
    [class~=#{"mr-" + $p + "5"}] { margin-right: ($spacer * 2) }
    [class~=#{"mr-" + $p + "6"}] { margin-right: ($spacer * 3) }
    [class~=#{"mr-" + $p + "7"}] { margin-right: ($spacer * 4) }
    [class~=#{"mr-" + $p + "8"}] { margin-right: ($spacer * 5) }

    [class~=#{"mx-" + $p + "0"}] { margin-left: ($spacer * 0); margin-right: ($spacer * 0) }
    [class~=#{"mx-" + $p + "1"}] { margin-left: ($spacer * .25); margin-right: ($spacer * .25) }
    [class~=#{"mx-" + $p + "2"}] { margin-left: ($spacer * .5); margin-right: ($spacer * .5) }
    [class~=#{"mx-" + $p + "3"}] { margin-left: ($spacer); margin-right: ($spacer) }
    [class~=#{"mx-" + $p + "4"}] { margin-left: ($spacer * 1.5); margin-right: ($spacer * 1.5) }
    [class~=#{"mx-" + $p + "5"}] { margin-left: ($spacer * 2); margin-right: ($spacer * 2) }
    [class~=#{"mx-" + $p + "6"}] { margin-left: ($spacer * 3); margin-right: ($spacer * 3) }
    [class~=#{"mx-" + $p + "7"}] { margin-left: ($spacer * 4); margin-right: ($spacer * 4) }
    [class~=#{"mx-" + $p + "8"}] { margin-left: ($spacer * 5); margin-right: ($spacer * 5) }

    [class~=#{"my-" + $p + "0"}] { margin-bottom: ($spacer * 0); margin-top: ($spacer * 0) }
    [class~=#{"my-" + $p + "1"}] { margin-bottom: ($spacer * .25); margin-top: ($spacer * .25) }
    [class~=#{"my-" + $p + "2"}] { margin-bottom: ($spacer * .5); margin-top: ($spacer * .5) }
    [class~=#{"my-" + $p + "3"}] { margin-bottom: ($spacer); margin-top: ($spacer) }
    [class~=#{"my-" + $p + "4"}] { margin-bottom: ($spacer * 1.5); margin-top: ($spacer * 1.5) }
    [class~=#{"my-" + $p + "5"}] { margin-bottom: ($spacer * 2); margin-top: ($spacer * 2) }
    [class~=#{"my-" + $p + "6"}] { margin-bottom: ($spacer * 3); margin-top: ($spacer * 3) }
    [class~=#{"my-" + $p + "7"}] { margin-bottom: ($spacer * 4); margin-top: ($spacer * 4) }
    [class~=#{"my-" + $p + "8"}] { margin-bottom: ($spacer * 5); margin-top: ($spacer * 5) }
}

@mixin padding($p) {
    [class~=#{"pt-" + $p + "1"}] { padding-top: ($spacer * .25) }
    [class~=#{"pt-" + $p + "2"}] { padding-top: ($spacer * .5) }
    [class~=#{"pt-" + $p + "3"}] { padding-top: ($spacer) }
    [class~=#{"pt-" + $p + "4"}] { padding-top: ($spacer * 1.5) }
    [class~=#{"pt-" + $p + "5"}] { padding-top: ($spacer * 2) }
    [class~=#{"pt-" + $p + "6"}] { padding-top: ($spacer * 3) }
    [class~=#{"pt-" + $p + "7"}] { padding-top: ($spacer * 4) }
    [class~=#{"pt-" + $p + "8"}] { padding-top: ($spacer * 5) }

    [class~=#{"pb-" + $p + "1"}] { padding-bottom: ($spacer * .25) }
    [class~=#{"pb-" + $p + "2"}] { padding-bottom: ($spacer * .5) }
    [class~=#{"pb-" + $p + "3"}] { padding-bottom: ($spacer) }
    [class~=#{"pb-" + $p + "4"}] { padding-bottom: ($spacer * 1.5) }
    [class~=#{"pb-" + $p + "5"}] { padding-bottom: ($spacer * 2) }
    [class~=#{"pb-" + $p + "6"}] { padding-bottom: ($spacer * 3) }
    [class~=#{"pb-" + $p + "7"}] { padding-bottom: ($spacer * 4) }
    [class~=#{"pb-" + $p + "8"}] { padding-bottom: ($spacer * 5) }

    [class~=#{"pl-" + $p + "1"}] { padding-left: ($spacer * .25) }
    [class~=#{"pl-" + $p + "2"}] { padding-left: ($spacer * .5) }
    [class~=#{"pl-" + $p + "3"}] { padding-left: ($spacer) }
    [class~=#{"pl-" + $p + "4"}] { padding-left: ($spacer * 1.5) }
    [class~=#{"pl-" + $p + "5"}] { padding-left: ($spacer * 2) }
    [class~=#{"pl-" + $p + "6"}] { padding-left: ($spacer * 3) }
    [class~=#{"pl-" + $p + "7"}] { padding-left: ($spacer * 4) }
    [class~=#{"pl-" + $p + "8"}] { padding-left: ($spacer * 5) }

    [class~=#{"pr-" + $p + "1"}] { padding-right: ($spacer * .25) }
    [class~=#{"pr-" + $p + "2"}] { padding-right: ($spacer * .5) }
    [class~=#{"pr-" + $p + "3"}] { padding-right: ($spacer) }
    [class~=#{"pr-" + $p + "4"}] { padding-right: ($spacer * 1.5) }
    [class~=#{"pr-" + $p + "5"}] { padding-right: ($spacer * 2) }
    [class~=#{"pr-" + $p + "6"}] { padding-right: ($spacer * 3) }
    [class~=#{"pr-" + $p + "7"}] { padding-right: ($spacer * 4) }
    [class~=#{"pr-" + $p + "8"}] { padding-right: ($spacer * 5) }

    [class~=#{"px-" + $p + "1"}] { padding-left: ($spacer * .25); padding-right: ($spacer * .25) }
    [class~=#{"px-" + $p + "2"}] { padding-left: ($spacer * .5); padding-right: ($spacer * .5) }
    [class~=#{"px-" + $p + "3"}] { padding-left: ($spacer); padding-right: ($spacer) }
    [class~=#{"px-" + $p + "4"}] { padding-left: ($spacer * 1.5); padding-right: ($spacer * 1.5) }
    [class~=#{"px-" + $p + "5"}] { padding-left: ($spacer * 2); padding-right: ($spacer * 2) }
    [class~=#{"px-" + $p + "6"}] { padding-left: ($spacer * 3); padding-right: ($spacer * 3) }
    [class~=#{"px-" + $p + "7"}] { padding-left: ($spacer * 4); padding-right: ($spacer * 4) }
    [class~=#{"px-" + $p + "8"}] { padding-left: ($spacer * 5); padding-right: ($spacer * 5) }

    [class~=#{"py-" + $p + "1"}] { padding-bottom: ($spacer * .25); padding-top: ($spacer * .25) }
    [class~=#{"py-" + $p + "2"}] { padding-bottom: ($spacer * .5); padding-top: ($spacer * .5) }
    [class~=#{"py-" + $p + "3"}] { padding-bottom: ($spacer); padding-top: ($spacer) }
    [class~=#{"py-" + $p + "4"}] { padding-bottom: ($spacer * 1.5); padding-top: ($spacer * 1.5) }
    [class~=#{"py-" + $p + "5"}] { padding-bottom: ($spacer * 2); padding-top: ($spacer * 2) }
    [class~=#{"py-" + $p + "6"}] { padding-bottom: ($spacer * 3); padding-top: ($spacer * 3) }
    [class~=#{"py-" + $p + "7"}] { padding-bottom: ($spacer * 4); padding-top: ($spacer * 4) }
    [class~=#{"py-" + $p + "8"}] { padding-bottom: ($spacer * 5); padding-top: ($spacer * 5) }
}

@include margin("");
@include padding("");

// [Extra Small] Screens larger than 600px
@media only screen and (min-width: $Mobile) {
    @include margin("xs-");
    @include padding("xs-");
}

// [Small] Screens larger than 800px
@media only screen and (min-width: $TabletSmall) {
    @include margin("sm-");
    @include padding("sm-");
}

// [Medium] Screens larger than 1000px
@media only screen and (min-width: $Tablet) {
    @include margin("md-");
    @include padding("md-");
}

// [Large] Screens larger than 1200px
@media only screen and (min-width: $Laptop) {
    @include margin("lg-");
    @include padding("lg-");
}

// [Extra Large] Screens larger than 1400px
@media only screen and (min-width: $LaptopLarge) {
    @include margin("xl-");
    @include padding("xl-");
}

// [Huge] Screens larger than 1600px
@media only screen and (min-width: $Desktop) {
    @include margin("hu-");
    @include padding("hu-");
}

// [Massive] Screens larger than 2000px
@media only screen and (min-width: $DesktopLarge) {
    @include margin("ma-");
    @include padding("ma-");
}

@import "breakpoints";

.accordion {
    &__container {
        position: relative;
        border-radius: 12px;
        padding: 0 8px;
        margin: 24px 0;

        @media only screen and (max-width: $Laptop) {
            margin: 16px 0;
            border-radius: 8px;
        }

        &--no-mt {
            margin-top: 0;
        }
    }

    &__header {
        display: flex;
        height: 40px;
        padding: 8px;

        font-family: var(--font-franklin);
        font-size: var(--font-size-h5);
        line-height: 1.25;
        text-transform: uppercase;
        user-select: none;
        cursor: pointer;

        @media only screen and (max-width: $Laptop) {
            font-size: var(--font-size-h3);
            padding: 8px 0;
        }

        @media only screen and (max-width: $Tablet) {
            height: auto;
        }

        &::before {
            content: "";
            position: absolute;
            left: 16px;
            height: 24px;
            width: 24px;
            margin: 0;

            transition: all .25s ease;
            opacity: 0;

            background-image: none;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            @media only screen and (max-width: $Laptop) {
                //top: 1px;
                left: 8px;
            }
        }

        span {
            transition: all .1s ease;
        }

        //&.error {
        //    span {
        //        margin-left: 28px;
        //    }
        //
        //    &::before {
        //        opacity: 1;
        //        background-image: url("/static/icons/error.svg");
        //    }
        //}

        &--unopenable {
            cursor: none;
        }

        &:not(&--unopenable) {
            &::after {
                content: "";
                position: absolute;
                top: 10px;
                right: 16px;
                height: 20px;
                width: 20px;
                transition: background-image .1s ease;
                background-image: url("/static/icons/plus.svg");
                background-repeat: no-repeat;
                background-size: 100% 100%;

                @media only screen and (max-width: $Laptop) {
                    right: 8px;
                }

                @media only screen and (max-width: $Tablet) {
                    height: 18px;
                    width: 18px;
                }
            }

            &.show::after {
                background-image: url("/static/icons/minus.svg");
            }
        }
    }

    &__body {
        transition: all .25s ease;
        max-height: 0;

        &:not(.show) {
            overflow: hidden;

            * {
                pointer-events: none;
                animation-duration: .25s;
                animation-direction: normal;
                animation-fill-mode: forwards;
                animation-name: consolidate-elements;

                @keyframes consolidate-elements {
                    0% {}
                    95% {
                        width: 100%;
                        height: auto;
                        position: relative;
                    }
                    100% {
                        position: absolute;
                        opacity: 0;
                        left: 0;
                        top: 0;
                        height: 0;
                        width: 0;
                    }
                }
            }
        }

        &.show {
            transition: max-height .5s ease;
            max-height: 500px;
        }
    }
}

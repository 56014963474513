section {
    container-type: inline-size; // Could be problematic
    position: relative;
    padding: 1.5rem 0;
    margin-bottom: 2rem;

    &.card {
        padding: 51px 43px;
        background-color: var(--colour-lemon);
        border-radius: 24px;

        min-height: calc(100vh - 102px - 32px);
    }

    @media only screen and (max-width: 600px) {
        container-type: normal;

        &.card {
            padding: 16px;
            margin-bottom: 5rem;
        }
    }
}

.section__header {
    display: grid;
    grid-template-columns: min-content 1fr 64px;
    place-content: center;

    h1 {
        margin: 0;
        text-align: center;
    }

    > span:first-of-type {
        padding: 14px 12px 13px 12px;
        background-color: var(--colour-light-grey);
        border-radius: .75rem;
        white-space: nowrap;
        line-height: 1;
        font-size: 14px;
        height: 44px;
    }

    > *:nth-child(3) {
        width: 44px;
        height: 44px;
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: min-content 44px;
        place-content: space-between;
        text-align: center;
        white-space: normal;

        > *:nth-child(1) {
            grid-column: 1;
            width: min-content;
            height: 44px;
        }

        > *:nth-child(2) {
            grid-column: 2;
            width: 44px;
            height: 44px;
        }

        > *:nth-child(3) {
            grid-column: 1 / -1;
            width: 100%;
            height: 100%;
        }
    }
}

@import "breakpoints";

$info-sharp: "../../assets/icons/info-sharp.svg";
$info-trans: "../../assets/icons/info-trans.svg";
$info-trans-active: "../../assets/icons/info-active-trans.svg";

.info__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info__body {
        opacity: 0;
        transition: transform .25s ease,
                    opacity .25s ease;
        transform: translateY(10px);
        pointer-events: none;
    }

    @media(hover: hover) {
        &:hover {
            .info__body {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }
    }

    @media(hover: none) {
        &:focus {
            .info__body {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }
    }
}

.info__box {
    margin: 16px 0;

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        flex-wrap: wrap;

        > div {
            position: relative;
        }

        p {
            margin: 0;
        }
    }

    &--bubble {
        width: 28px;
        height: 28px;
        margin: auto 12px;

        cursor: pointer;

        transition: all .25s ease;

        background-image: url($info-trans);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: left;

        &:hover {
            background-image: url($info-trans-active);
        }
    }

    &--body {
        max-height: 0;
        padding: 0 12px;

        border-radius: 6px;
        background-color: transparent;
        overflow: hidden;

        transition: all .25s ease;

        &-visible {
            max-height: 50rem;
            padding: 8px 12px;
            margin: 8px 0;

            background-color: black;
        }

        li, p, a {
            color: white;
            font-family: var(--font-ppeditorial);
            font-size: 14px;
            line-height: 18px;
        }

        li {
            line-height: 20px;
        }

        a {
            text-decoration: underline;
            transition: all .1s ease;

            &:hover {
                color: var(--colour-apricot)
            }
        }

        span {
            &.nobreak {
                white-space: nowrap;
            }
        }
    }
}

.info__bubble {
    @media only screen and (min-width: $Mobile) {
        position: absolute;
    }

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        margin-left: 8px;
        padding: 22px;
        height: 20px;
        width: 20px;
        transition: all .2s ease;

        background-image: url("/static/icons/info.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: center;
        transform: translate(-12px, -12px);

        @media only screen and (min-width: $Mobile) {
            top: calc(50% - 12px);
            margin-left: 16px;
            height: 24px;
            width: 24px;
            padding: 0;
            transform: translate(0);
        }
    }

    &:hover::after {
        background-image: url("/static/icons/info-active.svg");
    }

    &--transparent {
        &::after {
            background-image: url("/static/icons/info-trans.svg");
        }

        &:hover::after {
            background-image: url("/static/icons/info-active-trans.svg");
        }
    }
}

.info__body {
    position: absolute;
    text-align: left;
    text-transform: none;
    font-family: var(--font-ppeditorial);
    font-size: 14px;
    line-height: 18px;
    padding: 24px 16px;
    border-radius: 12px;

    z-index: 10;
    width: calc(100vw - 24px);
    top: 32px;

    &:after {
        content: "";
        height: 14px;
        width: 14px;
        border-radius: 4px 0 0 0;
        transform: rotate(45deg);
        position: absolute;
        background-color: black;
        top: -4px;
        left: calc(50% - 7px);

        @media only screen and (min-width: $Mobile) {
            //left: calc(50% - 7px);
        }
    }

    @media only screen and (min-width: $Mobile) {
        left: calc(28px - 150px);
        width: 300px;
        top: 24px;
    }
}

@use "../shared/variables" as var;
@import "inputs/mixins";
@import "inputs/files";
@import "inputs/combo";

.input__container {
    display: grid;
    grid-template-areas:
        "label"
        "any";

    > div {
        grid-area: any;
    }

    > label {
        grid-area: label;
    }
}

.input__container, .ic {
    &--horizontal, &--h, &-h {
        grid-template-areas: "label any";

        &-reverse, &r {
            grid-template-areas: "any label"
        }
    }

    &--vertical, &--v, &-v {
        grid-template-areas:
            "label"
            "any";

        &-reverse, &r {
            grid-template-areas:
                "any"
                "label";
        }
    }
}

.input--phone > div {
    position: relative;
    --prefix-width: 4rem;

    > div {
        position: absolute;
        display: grid;
        justify-content: center;
        align-content: center;
        width: var(--prefix-width);
        height: 100%;

        pointer-events: none;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    input {
        padding-left: calc(var(--prefix-width) + 20px);
    }

    &:before {
        position: absolute;
        content: "";
        height: 100%;
        width: 4rem;

        border-right: 1px solid #e5e5e5;
    }

    &:focus-within:before {
        border-right: 2px solid black;
    }
}

label[for], .label__header {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 20px;
    font-family: var(--font-franklin);
    text-transform: uppercase;
}

label:not([data-type="inline"]) {
    @media only screen and (max-width: var.$sm) {
        display: grid;
        grid-template-columns: 23px 1fr;
        grid-gap: .5rem;
    }
}

input, select, textarea {
    font-size: 16px;
    width: 100%;
    padding: 28px 20px 24px 20px;

    border-radius: 12px;
    border: 1px solid #e5e5e5;
    font-family: var(--font-ppeditorial);

    &::placeholder {
        color: var(--medium-grey-1);
    }
}

input, select {
    height: 76px;
}

textarea {
    height: 260px;
    resize: none;
}

select {
    appearance: none;
    background-color: transparent;
    z-index: 1;
}

option {
    height: 36px;
}

.select {
    position: relative;
    z-index: 1;
    background: white;
    border-radius: 12px;

    &, * {
        transition: all .2s ease,
                    background-color .1s ease;
    }

    &[data-open="true"] {
        .select__header > div {
            border-radius: 0 12px 0 0;

            svg {
                transform: rotateX(180deg);
            }
        }

        &, .select__header, .select__header > input {
            border-radius: 12px 12px 0 0;
        }

        .options {
            grid-template-rows: 1fr;
        }
    }
}

.select__header {
    position: relative;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    pointer-events: none;

    div:first-of-type {
        display: grid;
        place-content: center;
        pointer-events: auto;
        position: absolute;
        top: 0;
        right: 0;

        height: 76px;
        width: 4.5rem;
        z-index: 0;

        border-radius: 0 12px 12px 0;
        background-color: var(--colour-apricot);
        cursor: pointer;

        * {
            pointer-events: none;
        }
    }

    div:last-of-type {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        background-color: transparent;
        pointer-events: auto;
        cursor: pointer;
        user-select: none;
    }

    input {
        position: relative;
        pointer-events: none;
        background: transparent;
        z-index: 2;
        border: 0;
    }
}

.options {
    position: absolute;
    display: grid;
    grid-template-rows: 0fr;
    width: 100%;
    max-height: 285px;

    background: white;
    border-radius: 0 0 12px 12px;
    overflow-y: scroll;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;

    > div {
        min-height: 0;
    }

    > div > div {
        padding: 8px 20px 4px 20px;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;

        &:hover {
            background-color: var(--colour-apricot);
        }
    }
}

.form__button {
    display: grid;
    place-content: center;

    padding: .5rem 1.5rem;

    color: var(--colour-black);
    transition: all .25s ease;
    background-color: var(--colour-apricot);
    border-radius: 12px;

    font-family: var(--font-ppeditorial);
    font-size: .9rem;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: .25px;

    &:hover {
        background-color: var(--colour-black);
        color: var(--colour-white);
    }
}

.form__button--round {
    @include form-button;
}

.form__list {}

.form__row {
    display: grid;
    grid-template-columns: 1fr min-content;

    padding: 0 1.25rem;
    background-color: var(--colour-white);

    &:nth-of-type(2n+3) {
        background-color: #f7f7f7;
    }

    &:first-of-type {
        border-top-right-radius: .75rem;
        border-top-left-radius: .75rem;
    }

    &:last-of-type {
        border-bottom-right-radius: .75rem;
        border-bottom-left-radius: .75rem;
    }

    input {
        padding: .5rem;
        height: 100%;
        line-height: 1.5rem;

        border: 0;
        border-radius: 0;
        background-color: transparent;
    }

    > div:first-of-type {
        input {
            //padding-left: 0rem;
        }
    }

    > div:last-of-type {
        display: grid;
        place-content: center;
        width: 100%;

        border: 0;

        button {
            @include form-button;
        }
    }

    &--mobile {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        border-radius: 12px;
        padding: 0;

        @media only screen and (max-width: 400px) {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        > div:first-of-type {
            input {
                padding: .5rem;
            }
        }

        > div:last-of-type {
            display: block;
        }

        .form__row--header {
            padding: 0.5rem;

            .label-primary {
                font-size: .9rem;
            }

            .label-sub {
                margin-left: .25rem;
                font-size: .75rem;
            }
        }

        @media only screen and (min-width: 662px) {
            > div:nth-child(1) > div:first-child { border-top-left-radius: 12px }
            > div:nth-child(2) > div:first-child { border-top-right-radius: 12px }
        }

        @media only screen and (width > 600px) and (width <= 662px) {
            > div:nth-child(1) > div:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
        }

        @media only screen and (width >= 576px) and (width <= 600px) {
            > div:nth-child(1) > div:first-child { border-top-left-radius: 12px }
            > div:nth-child(2) > div:first-child { border-top-right-radius: 12px }
        }

        @media only screen and (width < 576px) {
            > div:nth-child(1) > div:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
        }
    }

    &--header {
        padding: 0.5rem 1.25rem;
        background-color: var(--colour-apricot);

        > div {
            padding-left: .5rem;
            border: 0;
        }

        > div:first-of-type {
            //padding: 0;
        }

        .label-primary {
            font-size: 1.15rem;
            font-weight: 700;
        }

        .label-sub {}
    }

    .select {
        background-color: transparent;
        min-width: 100%;

        .select__header {
            border: 0;
            border-radius: 0;

            div:first-of-type {
                width: 24px;
                height: 24px;
                margin-top: 8px;
                margin-right: 8px;

                border-radius: 12px;

                svg {
                    width: 13px;
                    height: 13px;
                    margin-left: 1px;
                    margin-top: 1px;
                }
            }

            &:hover {
                div:first-of-type {
                    background-color: var(--colour-black);

                    svg path {
                        stroke: var(--colour-white);
                    }
                }
            }
        }

        &[data-open="true"] {
            &, .select__header, .select__header > input {
                border-radius: 0;
            }
        }

        .options {
            > div > div {
                padding: 8px 7px 4px 7px;
            }
        }
    }
}

.form__row input[type="checkbox"] {
    appearance: none;
    position: relative;
    //padding: 0;
    margin: 0;

    //padding: 0.5rem;
    //height: 2.5rem;
    line-height: 1.5rem;

    //margin: 0;
    //height: 44px;
    width: 100%;

    //border-radius: 50%;
    //border: var(--colour-black) 1px solid;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 1.25rem;

        opacity: 0;
        transition: all .1s ease;

        --borderWidth: 4px;
        --height: 20px;
        --width: 12px;
        --borderColor: #000;
        transform: translate(0, calc(-50% - (var(--borderWidth) / 2))) rotate(45deg);
        //transform: translate(0, -50%) rotate(45deg);
        height: var(--height);
        width: var(--width);
        border-bottom: var(--borderWidth) solid var(--borderColor);
        border-right: var(--borderWidth) solid var(--borderColor);
    }

    &:checked::after {
        opacity: 1;
    }
}

@mixin form-box {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1.25rem;
    position: relative;

    padding: 1rem 1.25rem;
    height: calc(44px + 2rem);
    min-width: min-content;
    max-width: 350px;

    background-color: var(--colour-apricot);
    border-radius: .75rem;
    cursor: pointer;

    label {
        //display: grid;
        //grid-template-columns: 1fr;
        place-content: center;
        user-select: none;
        height: 100%;

        font-size: 1rem;
        font-family: var(--font-ppeditorial);
        text-transform: unset;
        margin: 0;
    }

    .tooltip {
        position: absolute;
        top: 0;
        right: 0;

        transform: translate(33%, -33%);
    }

    &:hover input[type="radio"]:not(:checked) {
        &::after {
            opacity: .2;
        }
    }

    &--wide {
        max-width: 100%;
    }

    @media only screen and (max-width: var.$sm) {
        min-width: 200px;
        max-width: 100%;

        label {
            place-content: start;
            align-content: center;
        }
    }
}

.form__radio {
    @include form-box;
}

.form__checkbox {
    @include form-box;
    --checkbox-size: 2rem;
}

.form__checkbox input[type="checkbox"] {
    appearance: none;
    padding: 0;
    margin: 0;

    width: var(--checkbox-size);
    min-height: var(--checkbox-size);
    height: var(--checkbox-size);
    border: 0;

    &::before, &::after {
        position: absolute;
        display: block;
        top: calc(50% - var(--checkbox-size)/2);
        width: var(--checkbox-size);
        height: var(--checkbox-size);

        content: "";
        border-radius: 0.375rem;
        pointer-events: none;
        transition: all .2s ease;
    }

    &::before {
        background-color: var(--colour-white);
        border: 0 solid #e5e5e5;
    }

    &::after {
        background: no-repeat center;
        background-size: 50%;
    }

    &[value="true"] {
        &::before {
            color: #fff;
            border-color: var(--colour-black);
            background-color: var(--colour-black);
        }

        &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
        }
    }
}

.form__radio input[type="radio"], .form__radio input[type="checkbox"] {
    appearance: none;
    position: relative;

    padding: 0;
    margin: 0;
    height: 44px;
    width: 44px;

    border-radius: 50%;
    border: var(--colour-black) 1px solid;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;

        height: 32px;
        width: 32px;

        opacity: 0;
        background-color: black;
        transition: all .1s ease;
        border-radius: 50%;
    }

    &:checked::after {
        opacity: 1;
    }
}

input[type="date"] {
    appearance: none;
    position: relative;
    cursor: pointer;
    z-index: 0;
    min-width: 200px;

    &::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;

        height: 76px;
        //height: 100%;
        width: 4.5rem;
        z-index: -1;

        border-radius: 0 12px 12px 0;
        background-color: var(--colour-apricot);
    }

    &::after {
        content: url('data:image/svg+xml;utf8, <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 4H23V3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2C21.7348 2 21.4804 2.10536 21.2929 2.29289C21.1054 2.48043 21 2.73478 21 3V4H11V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3V4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4ZM9 6V7C9 7.26522 9.10536 7.51957 9.29289 7.70711C9.48043 7.89464 9.73478 8 10 8C10.2652 8 10.5196 7.89464 10.7071 7.70711C10.8946 7.51957 11 7.26522 11 7V6H21V7C21 7.26522 21.1054 7.51957 21.2929 7.70711C21.4804 7.89464 21.7348 8 22 8C22.2652 8 22.5196 7.89464 22.7071 7.70711C22.8946 7.51957 23 7.26522 23 7V6H26V10H6V6H9ZM26 26H6V12H26V26Z" fill="black"/></svg>');
        position: absolute;
        top: 0;
        right: 0;

        transform: translate(-50%, 50%);
    }
}

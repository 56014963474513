%grid-layout {
    // Inspired by https://ryanmulligan.dev/blog/layout-breakouts/
    --gap: max(1rem, 2.5%);
    --full: minmax(var(--gap), 1fr);
    //--content: min(1080px, 100% - var(--gap) * 2);
    --content: minmax(0, 10rem);
    --small: minmax(0, 8.75rem);
    --short: min(480px, 100% - var(--gap) * 2);
    --feature: minmax(0, 1fr);
    --semifull: minmax(0, 5%);

    display: grid;
    grid-template-columns:
            [full-start] var(--full)
            [semifull-start] var(--semifull)
            [feature-start] var(--feature)
            [content-start] var(--content)
            [small-start] var(--small)
            [short-start] var(--short) [short-end]
            var(--small) [small-end]
            var(--content) [content-end]
            var(--feature) [feature-end]
            var(--semifull) [semifull-end]
            var(--full) [full-end];

    > * {
        grid-column: content;
    }

    .content-small {
        grid-column: small;
    }

    .content-short {
        grid-column: short;
    }

    .feature {
        grid-column: feature;
    }

    .semi-full {
        grid-column: semifull;
    }

    .full {
        grid-column: full;
    }
}

#__mekle {
    @import "../shared/helpers";
    @import "./breakpoints";
    @import "./animations";
    @import "./cards";
    @import "./grid";
    @import "./typography";
    @import "./form";
    @import "./funnel";
    @import "./buttons";
    @import "./accordion";
    @import "./backgrounds";
    @import "./inputs";
    @import "./loaders";
    @import "./hamburger";
    @import "./info";
    @import "./checkmark";
    @import "../variants/help";
    @import "./spacing"; // Should be at the bottom of the list.

    /************************/
    /****** Variables ******/
    //Miscellaneous
    $nav_height: 84px;
    $footer_height: 112px;
    $section_padding: 3rem;
    /************************/

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .stop-scrolling {
        //height: 100%;
        //overflow: hidden;

        position: fixed;
        overflow-y: scroll;
    }

    main {
        width: calc(100% - 8px);
        max-height: calc(100% - ($nav_height + $footer_height));
        margin: $nav_height 4px $footer_height;
        border-radius: 24px;
        position: absolute;
        top: 0;

        //-ms-overflow-style: none; /* for Internet Explorer, Edge */
        //scrollbar-width: none; /* for Firefox */
        //overflow-y: scroll;
        //
        //&::-webkit-scrollbar {
        //    display: none; /* for Chrome, Safari, and Opera */
        //}

        @media only screen and (max-width: $Mobile) {
            width: 100%;
            max-height: calc(100% - $nav_height);
            margin: $nav_height 0 0;
        }
    }

    .whereby {
        width: calc(100vw - 16px);
        height: calc(100vh - ($nav_height + 16px));
        margin-top: $nav_height;
        border: none;
        border-radius: 24px;
    }

    .section {
        &--cover {
            flex: none;
            min-height: 100vh;

            &:first-child, &:first-child:last-child {
                min-height: calc(100vh - 6rem);

                &.section--center {
                    min-height: calc(100vh - 3rem);
                }
            }

            @media only screen and (max-width: $Tablet) {
                &:first-child, &:first-child:last-child {
                    min-height: calc(100vh - 4rem);

                    &.section--center {
                        min-height: calc(100vh - 2rem);
                    }
                }
            }
        }

        &--center {
            justify-content: center;
        }

        @media only screen and (min-width: $Laptop) and (max-height: 650px) {
            &--cut-top {
                padding-top: 0;
            }
        }
    }

    section {
        overflow: hidden;
        position: relative;
        display: flex;
        padding: 3rem 0;
        margin: .75rem 0;

        @media only screen and (min-width: $Laptop) and (max-height: 650px) {
            padding: 1.5rem 0;
            margin: .5rem 0;

            &.cut--top {
                &:first-child {
                    padding-top: 0;
                }
            }
        }

        @media only screen and (max-width: $Tablet) {
            padding: 1.5rem 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    nav {
        z-index: 1000;
        position: fixed;
        top: 0;

        width: 100%;
        height: $nav_height;
        padding: 24px;
        background-color: #fff;

        .nav__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &--left {
                cursor: pointer;
                height: 32px;
                content: url("/static/logo/Mekle_Logo_Digital_Black.svg");
            }

            &--right {
                display: flex;
                flex-direction: row;
                justify-content: right;

                .hamburger {
                    cursor: pointer;
                    padding: 12px 28px;
                    border-radius: 12px;
                    transition: background-image .1s ease;
                    background-image: url("/static/icons/hamburger.svg");
                    height: 32px;
                    width: 76px;
                    background-repeat: no-repeat;
                    background-position: center;
                    //display: none;

                    &.open {
                        background-image: url("/static/icons/close.svg");
                    }
                }

                > * {
                    margin-left: 32px;
                }

                > *:nth-child(5) {
                    margin-left: 40px;
                }

                > *:nth-child(6), > *:nth-child(7) {
                    margin-left: 24px;
                }

                @media only screen and (max-width: $Laptop) {
                    > * {
                        display: none;
                    }

                    .hamburger {
                        display: block;
                    }
                }
            }
        }
    }

    footer {
        padding: 120px 0;
        margin: .75rem 0;

        a {
            cursor: pointer;
            color: var(--colour-lemon);
            display: block;
        }

        @media only screen and (max-width: $Laptop) {
            padding: 64px 20px 96px;
        }
    }

    .footer {
        pointer-events: none;
        z-index: 1000;
        position: fixed;
        bottom: 0;
        left: 0;

        height: $footer_height;
        background-color: #fff;
        width: 121px;
        padding: 48px 32px 38px;
        border-radius: 0 24px 0 0;

        @media only screen and (max-width: $Laptop) {
            display: none;
        }

        .footer__row {
            &--left {
                pointer-events: auto;
                height: 26px;
                content: url("/static/logo/Mekle_Symbol_Digital_Black.svg");
            }
        }

        &__contact, &__links {
            a {
                white-space: nowrap;
            }
        }

        &__contact {
            a {
                font-size: 1.5rem;
                margin-bottom: 24px;
            }
        }

        &__links {
            a {
                font-size: 1.125rem;
                height: 40px;
                margin-bottom: 10px;
            }
        }
    }
}

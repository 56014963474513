.form__continue {
    display: grid;
    grid-auto-flow: column;
    place-content: start;
    grid-gap: 1rem;

    margin-top: 16px;

    * {
        //width: min-content;
    }

    div {
        display: grid;
        place-content: center;
        white-space: nowrap;

        font-size: 12px;
    }
}

.error__box {
    display: grid;
    grid-auto-flow: column;
    place-content: start;
    grid-gap: 4px;

    margin-top: 16px;
    padding: 4px 12px 4px 8px;
    min-height: 32px;
    width: min-content;

    outline: 0;
    background-color: rgb(247, 230, 230);
    border-radius: 3px;

    span {
        height: 24px;
        white-space: nowrap;

        color: rgb(175, 4, 4);
        svg {
            fill: rgb(175, 4, 4);
        }
    }
}

h1 {
    font-size: 2.75rem;

    @media only screen and (max-width: 600px) {
        font-size: 2.33rem;
    }
}

h2 {
    font-size: 2rem;

    @media only screen and (max-width: 600px) {
        font-size: 1.75rem;
    }
}

h3 {
    font-size: 1.75rem;

    @media only screen and (max-width: 600px) {
        font-size: 1.5rem;
    }
}

h4 {
    font-size: 1.5rem;
    font-weight: 400;
}

p {
    font-size: 1.25rem;
}

@import "./font-faces";
@import "./shared/variables";
@import "./mekle/application";
@import "./v2/application";
@import "contested-claims/v1/application";
@import "contested-claims/v2/application";

html,
body {
    line-height: 1.55;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;

    background-color: var(--colour-white);
    color: var(--colour-black);
    font-family: var(--font-ppeditorial);

    button {
        cursor: pointer;
        border: none;
    }
}

body {
    overflow-y: scroll;
}

*, ::after, ::before {
    box-sizing: border-box;
}

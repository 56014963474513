@import "../../mekle/breakpoints";
@import "./keyframes";

.questions__wrapper {
    transition: all .25s ease;

    &.hidden {
        pointer-events: none;
        opacity: 0;
    }

    .hidden {
        pointer-events: none;
        opacity: 0;
    }
}

.question__content {
    z-index: 50;
    pointer-events: auto;
    width: min(720px, 100% - 80px);
    margin: 0 auto;
}

.question__container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    display: grid;
    place-content: center;
    transition: all .2s ease;
    pointer-events: none;

    grid-template-columns: 1fr;

    .scroll-down-in, .scroll-down-out, .scroll-up-in, .scroll-up-out {
        animation-duration: .25s;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    .scroll-up-in {
        animation-name: scroll-up-in;
    }

    .scroll-up-out {
        animation-name: scroll-up-out;
        pointer-events: none;
    }

    .scroll-down-in {
        animation-name: scroll-down-in;
    }

    .scroll-down-out {
        animation-name: scroll-down-out;
        pointer-events: none;
    }
}

.question__header {
    font-size: 1.5rem;

    a {
        @media only screen and (max-width: $Mobile) {
            font-size: 16px;
        }
    }

    &--link {
        margin-top: 16px;

        @media only screen and (max-width: $Mobile) {
            font-size: 16px;
        }
    }

    &--description {
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);

        @media only screen and (max-width: $Mobile) {
            font-size: 16px;
        }
    }

    &--counter {
        position: absolute;
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: 1fr;

        transform: translate(-40px, 6px);
        font-size: 1rem;

        span {
            display: grid;
            place-content: center;
        }
    }

    @media only screen and (max-width: $Mobile) {
        font-size: 1.25rem;

        &--counter {
            font-size: 0.875rem;
            line-height: 1.25rem;
            transform: translate(-32px, 4px);
        }
    }
}

.question__body {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    place-content: start;

    margin-top: 32px;
}

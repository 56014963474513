footer {
    > div {
        pointer-events: none;
        z-index: 1000;
        position: fixed;
        bottom: 0;
        left: 0;

        height: var(--footer--height);
        background-color: transparent;
        width: 121px;
        padding: 48px 32px 38px;
        border-radius: 0 24px 0 0;

        > div:first-child {
            pointer-events: auto;
            height: 26px;
            content: url("/static/logo/Mekle_Symbol_Digital_Black.svg");
        }

        @media only screen and (max-width: 1000px) {
            display: none;
        }
    }
}

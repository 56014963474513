#__contested-claims-v2 {
    @import "../../shared/extensions";
    @import "../../shared/layout";
    @import "../shared/loading";
    @import "../shared/footer";
    @import "../shared/nav";
    @import "./typography";
    @import "./keyframes";
    @import "./questions";
    @import "./input";
    @import "./form";

    main {
        @extend %grid-layout;
    }

    div {
        transition: all .2s ease;

        &.disabled {
            opacity: .5;

            * {
                pointer-events: none;
            }
        }
    }

    section {
        container-type: inline-size; // Could be problematic
        position: relative;
        padding: 1.5rem 0;
    }

    nav {
        @media only screen and (max-width: 1000px) {
            background-color: white;
        }
    }
}

@keyframes swoosh-loader {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.loader {
    &__container {
        display: inline-block;
        position: relative;
        transition: opacity .2s ease;
        transform: scale(1.5);
        opacity: 0;
        height: .6rem;

        &.show {
            opacity: 1;
        }

        &--large {
            transform: scale(3);
        }

        &--center {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &--restrict {
            width: 100%;
            padding: 1rem 0;
            overflow: hidden;
        }
    }

    &--swoosh {
        position: relative;
        border-right: .1em solid currentcolor;
        border-radius: 100%;
        animation: swoosh-loader 800ms linear infinite;

        &:before,
        &:after {
            content: '';
            width: .8em;
            height: .8em;
            display: block;
            position: absolute;
            top: calc(50% - .4em);
            left: calc(50% - .4em);
            border-left: .08em solid currentcolor;
            border-radius: 100%;
            animation: swoosh-loader 400ms linear infinite reverse;
        }

        &:after {
            width: .6em;
            height: .6em;
            top: calc(50% - .3em);
            left: calc(50% - .3em);
            border: 0;
            border-right: .05em solid currentcolor;
            animation: none;
        }
    }
}

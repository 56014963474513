@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* *****************************************************************************************
 * PPEditorialNew
 * Regular weight: 400
 * Bold weight: 700
 */

//$PPEditorialNew_EOT_Bold: "/static/fonts/PPEditorialNew/PPEditorialNew-Bold.eot";
//$PPEditorialNew_EOT_Bold_Italic: "/static/fonts/PPEditorialNew/PPEditorialNew-BoldItalic.eot";
//$PPEditorialNew_EOT_Italic: "/static/fonts/PPEditorialNew/PPEditorialNew-Italic.eot";
//$PPEditorialNew_EOT_Regular: "/static/fonts/PPEditorialNew/PPEditorialNew-Regular.eot";
//
//$PPEditorialNew_WOFF_Bold: "/static/fonts/PPEditorialNew/PPEditorialNew-Bold.woff";
//$PPEditorialNew_WOFF_Bold_Italic: "/static/fonts/PPEditorialNew/PPEditorialNew-BoldItalic.woff";
//$PPEditorialNew_WOFF_Italic: "/static/fonts/PPEditorialNew/PPEditorialNew-Italic.woff";
//$PPEditorialNew_WOFF_Regular: "/static/fonts/PPEditorialNew/PPEditorialNew-Regular.woff";
//
//$PPEditorialNew_WOFF2_Bold: "/static/fonts/PPEditorialNew/PPEditorialNew-Bold.woff2";
//$PPEditorialNew_WOFF2_Bold_Italic: "/static/fonts/PPEditorialNew/PPEditorialNew-BoldItalic.woff2";
//$PPEditorialNew_WOFF2_Italic: "/static/fonts/PPEditorialNew/PPEditorialNew-Italic.woff2";
//$PPEditorialNew_WOFF2_Regular: "/static/fonts/PPEditorialNew/PPEditorialNew-Regular.woff2";

/* Regular */
@font-face {
    font-family: "PP Editorial New";
    font-weight: 400;
    src: url(../fonts/PPEditorialNew/PPEditorialNew-Regular.woff2) format("woff2"),
         url(../fonts/PPEditorialNew/PPEditorialNew-Regular.woff) format("woff"),
         url(../fonts/PPEditorialNew/PPEditorialNew-Regular.eot) format("eot");
    font-style: normal;
    font-display: swap;
}

/* Bold */
@font-face {
    font-family: "PP Editorial New";
    font-weight: 700;
    src: url(../fonts/PPEditorialNew/PPEditorialNew-Bold.woff2) format("woff2"),
         url(../fonts/PPEditorialNew/PPEditorialNew-Bold.woff) format("woff"),
         url(../fonts/PPEditorialNew/PPEditorialNew-Bold.eot) format("eot");
    font-style: normal;
    font-display: swap;
}

/* Regular Italic */
@font-face {
    font-family: "PP Editorial New";
    font-weight: 400;
    src: url(../fonts/PPEditorialNew/PPEditorialNew-Italic.woff2) format("woff2"),
         url(../fonts/PPEditorialNew/PPEditorialNew-Italic.woff) format("woff"),
         url(../fonts/PPEditorialNew/PPEditorialNew-Italic.eot) format("eot");
    font-style: italic;
    font-display: swap;
}

/* Bold Italic */
@font-face {
    font-family: "PP Editorial New";
    font-weight: 700;
    src: url(../fonts/PPEditorialNew/PPEditorialNew-BoldItalic.woff2) format("woff2"),
         url(../fonts/PPEditorialNew/PPEditorialNew-BoldItalic.woff) format("woff"),
         url(../fonts/PPEditorialNew/PPEditorialNew-BoldItalic.eot) format("eot");
    font-style: italic;
    font-display: swap;
}

/* *****************************************************************************************
 * FranklinGothicCondensed
 * Book weight: 300
 * Medium weight: 500
 * Demi weight: 600
 */

$FranklinGothicCondensed_WOFF_Book: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Book.woff";
$FranklinGothicCondensed_WOFF_Book_Italic: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Book-Italic.woff";
$FranklinGothicCondensed_WOFF_Medium: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Medium.woff";
$FranklinGothicCondensed_WOFF_Medium_Italic: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Medium-Italic.woff";
$FranklinGothicCondensed_WOFF_Demi: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Demi.woff";
$FranklinGothicCondensed_WOFF_Demi_Italic: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Demi-Italic.woff";

$FranklinGothicCondensed_WOFF2_Book: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Book.woff2";
$FranklinGothicCondensed_WOFF2_Book_Italic: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Book-Italic.woff2";
$FranklinGothicCondensed_WOFF2_Medium: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Medium.woff2";
$FranklinGothicCondensed_WOFF2_Medium_Italic: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Medium-Italic.woff2";
$FranklinGothicCondensed_WOFF2_Demi: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Demi.woff2";
$FranklinGothicCondensed_WOFF2_Demi_Italic: "/static/fonts/FranklinGothicCondensed/FranklinGothicCondensed-Demi-Italic.woff2";

@font-face {
    font-family: "FranklinGothicCondensed";
    font-weight: 300;
    src: url($FranklinGothicCondensed_WOFF2_Book) format("woff2"),
         url($FranklinGothicCondensed_WOFF_Book) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FranklinGothicCondensed";
    font-weight: 300;
    src: url($FranklinGothicCondensed_WOFF2_Book-Italic) format("woff2"),
         url($FranklinGothicCondensed_WOFF_Book-Italic) format("woff");
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "FranklinGothicCondensed";
    font-weight: 500;
    src: url($FranklinGothicCondensed_WOFF2_Medium) format("woff2"),
         url($FranklinGothicCondensed_WOFF_Medium) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FranklinGothicCondensed";
    font-weight: 500;
    src: url($FranklinGothicCondensed_WOFF2_Medium_Italic) format("woff2"),
         url($FranklinGothicCondensed_WOFF_Medium_Italic) format("woff");
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "FranklinGothicCondensed";
    font-weight: 600;
    src: url($FranklinGothicCondensed_WOFF2_Demi) format("woff2"),
         url($FranklinGothicCondensed_WOFF_Demi) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FranklinGothicCondensed";
    font-weight: 600;
    src: url($FranklinGothicCondensed_WOFF2_Demi_Italic) format("woff2"),
         url($FranklinGothicCondensed_WOFF_Demi_Italic) format("woff");
    font-style: italic;
    font-display: swap;
}

/* *****************************************************************************************
 * FuturaPT
 * Light weight: 300
 * Book weight: 400
 * Medium weight: 450
 * Demi weight: 500
 * Heavy weight: 600
 * Bold weight: 700
 * Extra bold weight: 800
 */

$FuturaPT_WOFF_Light: "/static/fonts/FuturaPT/FuturaPT-Light.woff";
$FuturaPT_WOFF_LightOblique: "/static/fonts/FuturaPT/FuturaPT-LightObl.woff";
$FuturaPT_WOFF_Book: "/static/fonts/FuturaPT/FuturaPT-Book.woff";
$FuturaPT_WOFF_BookOblique: "/static/fonts/FuturaPT/FuturaPT-BookObl.woff";
$FuturaPT_WOFF_Medium: "/static/fonts/FuturaPT/FuturaPT-Medium.woff";
$FuturaPT_WOFF_MediumOblique: "/static/fonts/FuturaPT/FuturaPT-MediumObl.woff";
$FuturaPT_WOFF_Demi: "/static/fonts/FuturaPT/FuturaPT-Demi.woff";
$FuturaPT_WOFF_DemiOblique: "/static/fonts/FuturaPT/FuturaPT-DemiObl.woff";
$FuturaPT_WOFF_Heavy: "/static/fonts/FuturaPT/FuturaPT-Heavy.woff";
$FuturaPT_WOFF_HeavyOblique: "/static/fonts/FuturaPT/FuturaPT-HeavyObl.woff";
$FuturaPT_WOFF_Bold: "/static/fonts/FuturaPT/FuturaPT-Bold.woff";
$FuturaPT_WOFF_BoldOblique: "/static/fonts/FuturaPT/FuturaPT-BoldObl.woff";
$FuturaPT_WOFF_ExtraBold: "/static/fonts/FuturaPT/FuturaPT-ExtraBold.woff";
$FuturaPT_WOFF_ExtraBoldOblique: "/static/fonts/FuturaPT/FuturaPT-ExtraBoldObl.woff";

@font-face {
    font-family: "FuturaPt";
    font-weight: 300;
    src: url($FuturaPT_WOFF_Light) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 300;
    src: url($FuturaPT_WOFF_LightOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 400;
    src: url($FuturaPT_WOFF_Book) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 400;
    src: url($FuturaPT_WOFF_BookOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 450;
    src: url($FuturaPT_WOFF_Medium) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 450;
    src: url($FuturaPT_WOFF_MediumOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 500;
    src: url($FuturaPT_WOFF_Demi) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 500;
    src: url($FuturaPT_WOFF_DemiOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 600;
    src: url($FuturaPT_WOFF_Heavy) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 600;
    src: url($FuturaPT_WOFF_HeavyOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 700;
    src: url($FuturaPT_WOFF_Bold) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 700;
    src: url($FuturaPT_WOFF_BoldOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 800;
    src: url($FuturaPT_WOFF_ExtraBold) format("woff");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FuturaPt";
    font-weight: 800;
    src: url($FuturaPT_WOFF_ExtraBoldOblique) format("woff");
    font-style: oblique;
    font-display: swap;
}

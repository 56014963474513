@use "../shared/variables" as var;

& {
    --font-size-p: 1.25rem;
    --font-size-li: 1.25rem;

    @media only screen and (width <= var.$sm) {
        --font-size-p: 1rem;
        --font-size-li: 1rem;
    }
}

@mixin transforming($prefix: "") {
    .#{$prefix}align {
        &--centre, &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }
}

@include transforming();

@media only screen and (width > var.$lg) {
    @include transforming("lg-");
}

@media only screen and (width > var.$sm) and (width <= var.$lg) {
    @include transforming("md-");
}

@media only screen and (width <= var.$sm) {
    @include transforming("sm-");
}

p {
    font-size: var(--font-size-p);
}

li {
    font-size: var(--font-size-li);
}
